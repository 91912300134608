import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  InputAdornment,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TablePagination,
  Select, 
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Delete, Edit } from '@mui/icons-material';
import axios from 'axios';

const ProfessionalComponent = () => {
  const [professionals, setProfessionals] = useState([]);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('nombre');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentProfessional, setCurrentProfessional] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseModalOpen, setResponseModalOpen] = useState(false);


  useEffect(() => {
    fetchProfessionals();
  }, []);

  const fetchProfessionals = async () => {
    try {
      const response = await axios.get('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-profesionales');
      setProfessionals(response.data);
    } catch (error) {
      console.error('Error fetching professionals:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCurrentProfessional({ ...currentProfessional, [name]: value });
  };

  const handleAddProfessional = async () => {


      // Verificar que currentProfessional no sea null
    if (!currentProfessional) {
      setResponseMessage('Ocurrió un error inesperado. Por favor, intente nuevamente.');
      setResponseModalOpen(true);
      return;
    }

    // Validar que el RUT esté presente
    if (!currentProfessional.rut || currentProfessional.rut.trim() === '') {
      setResponseMessage('El RUT es obligatorio. Por favor, ingrese un RUT.');
      setResponseModalOpen(true);
      return;
    }

      // Validar el RUT antes de continuar
    if (!validarRut(currentProfessional.rut)) {
      setResponseMessage('RUT inválido. Por favor, ingrese un RUT válido.');
      setResponseModalOpen(true);
      return;
    }

    // Validar que la especialidad esté seleccionada
    if (!currentProfessional.especialidad) {
      setResponseMessage('La especialidad es obligatoria. Por favor, seleccione una especialidad.');
      setResponseModalOpen(true);
      return;
    }

    try {
      const response = await axios.post('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/ingreso-profesional', currentProfessional);
      const message = response.data.message || 'Profesional insertado correctamente';
      //setProfessionals([...professionals, response.data]);
      //setProfessionals(professionals.map(pro => (pro.profesional_id === currentProfessional.profesional_id ? currentProfessional : pro)));
      
      // Refrescar la lista de profesionales después de agregar uno nuevo
      fetchProfessionals();



      // Mostrar el mensaje de éxito en el modal
      setResponseMessage(message);
      setResponseModalOpen(true);
      setAddModalOpen(false);
    } catch (error) {

      console.error('Error adding professional:', error);
      const errorMessage = error.response?.data?.message || 'Error al insertar el profesional';
      setResponseMessage(errorMessage);
      setResponseModalOpen(true);

    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEditProfessional = (professional) => {
    setCurrentProfessional(professional);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setCurrentProfessional(null);
  };

  const handleUpdateProfessional = async () => {
    try {
      const response = await axios.post(`https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/actualizar-profesional`, currentProfessional);
      const message = response.data.message || 'Profesional actualizado correctamente';
      fetchProfessionals();
      setResponseMessage(message);
      setResponseModalOpen(true);
      setEditModalOpen(false);
      fetchProfessionals();
    } catch (error) {
      console.error('Error updating professional:', error);
    }
  };

  const handleDeleteProfessional = (id) => {
    setConfirmDeleteId(id);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/eliminar-profesional?profesional_id=${confirmDeleteId}`);
      setProfessionals(professionals.filter(professional => professional.profesional_id !== confirmDeleteId));
      setConfirmDeleteOpen(false);
      setConfirmDeleteId(null);
    } catch (error) {
      console.error('Error deleting professional:', error);
    }
  };

  const handleCloseConfirmDelete = () => {
    setConfirmDeleteOpen(false);
    setConfirmDeleteId(null);
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
    setCurrentProfessional({
      nombre: '',
      rut: '',
      fecha_nacimiento: '',
      genero: '',
      direccion: '',
      telefono: '',
      correo_electronico: '',
      especialidad: '',
      horarios_trabajo: '',
      coordinador: ''
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validarRut = (rutCompleto) => {
    // Eliminar los puntos y guiones
    rutCompleto = rutCompleto.replace(/\./g, '').replace('-', '');

    // Verificar que el formato básico sea válido
    if (!/^[0-9]+[0-9kK]{1}$/.test(rutCompleto)) {
      return false;
    }

    // Separar el número del dígito verificador
    const rut = rutCompleto.slice(0, -1);
    const dv = rutCompleto.slice(-1).toUpperCase();

    // Calcular el dígito verificador
    let suma = 0;
    let multiplo = 2;

    for (let i = rut.length - 1; i >= 0; i--) {
      suma += parseInt(rut.charAt(i)) * multiplo;
      multiplo = multiplo === 7 ? 2 : multiplo + 1;
    }

    const dvEsperado = 11 - (suma % 11);
    const dvFinal = dvEsperado === 11 ? '0' : dvEsperado === 10 ? 'K' : dvEsperado.toString();

    // Comparar el dígito verificador calculado con el ingresado
    return dvFinal === dv;
  };


  const sortedProfessionals = professionals.sort((a, b) => {
    const fieldA = a[orderBy] || '';
    const fieldB = b[orderBy] || '';

    if (orderDirection === 'asc') {
      return fieldA.localeCompare(fieldB);
    } else {
      return fieldB.localeCompare(fieldA);
    }
  });

  const filteredProfessionals = sortedProfessionals.filter((professional) =>
    (professional.nombre && professional.nombre.toLowerCase().includes(search.toLowerCase())) ||
    (professional.rut && professional.rut.toLowerCase().includes(search.toLowerCase())) ||
    (professional.especialidad && professional.especialidad.toLowerCase().includes(search.toLowerCase()))
  );

  const paginatedProfessionals = filteredProfessionals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ boxShadow: 6, padding: 2 }}>
        <Box
          sx={{ backgroundColor: '#003C43', flexGrow: 1, display: 'flex', padding: 1, borderRadius: 1, marginBottom: 2, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h6" color="white" sx={{ marginLeft: 6 }}>
            Profesionales
          </Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#003C43', color: 'white', '&:hover': { backgroundColor: '#134566' } }}
            onClick={() => setAddModalOpen(true)}
          >
            Agregar Profesional
          </Button>
        </Box>

        <TextField
          fullWidth
          placeholder="Buscar por nombre o rut o especialidad..."
          value={search}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          sx={{ marginBottom: 2 }}
        />

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'nombre'}
                    direction={orderBy === 'nombre' ? orderDirection : 'asc'}
                    onClick={() => handleSortRequest('nombre')}
                  >
                    Nombre
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'rut'}
                    direction={orderBy === 'rut' ? orderDirection : 'asc'}
                    onClick={() => handleSortRequest('rut')}
                  >
                    RUT
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'especialidad'}
                    direction={orderBy === 'especialidad' ? orderDirection : 'asc'}
                    onClick={() => handleSortRequest('especialidad')}
                  >
                    Especialidad
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'equipo_nombre'}
                    direction={orderBy === 'equipo_nombre' ? orderDirection : 'equipo_nombre'}
                    onClick={() => handleSortRequest('equipo_nombre')}
                  >
                    Equipo
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'horarios_trabajo'}
                    direction={orderBy === 'horarios_trabajo' ? orderDirection : 'horarios_trabajo'}
                    onClick={() => handleSortRequest('horarios_trabajo')}
                  >
                    Tipo Horario
                  </TableSortLabel>
                </TableCell>

                
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProfessionals.map((professional) => (
                <TableRow key={professional.profesional_id}>
                  <TableCell>{professional.nombre}</TableCell>
                  <TableCell>{professional.rut}</TableCell>
                  <TableCell>{professional.especialidad}</TableCell>
                  <TableCell>{professional.equipo_nombre ? professional.equipo_nombre : 'Sin Equipo'}</TableCell>
                  <TableCell>{professional.horarios_trabajo}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Edit />}
                      onClick={() => handleEditProfessional(professional)}
                      sx={{ marginRight: 1 }}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<Delete />}
                      onClick={() => handleDeleteProfessional(professional.profesional_id)}
                    >
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          labelRowsPerPage="Filas por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredProfessionals.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Modal para editar profesional */}
      <Dialog
        open={editModalOpen}
        onClose={handleCloseEditModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Editar Profesional</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="nombre"
            label="Nombre"
            fullWidth
            value={currentProfessional?.nombre || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="rut"
            label="RUT"
            fullWidth
            value={currentProfessional?.rut || ''}
            onChange={handleChange}
            disabled
          />
          <TextField
            margin="dense"
            name="fecha_nacimiento"
            label="Fecha de Nacimiento"
            type='date'
            fullWidth
            value={currentProfessional?.fecha_nacimiento || ''}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <FormControl fullWidth margin="dense">
            <InputLabel>Género</InputLabel>
            <Select
              name="genero"
              value={currentProfessional?.genero || ''}
              onChange={handleChange}
            >
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="Femenino">Femenino</MenuItem>
              <MenuItem value="Otro">Otro</MenuItem>
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            name="direccion"
            label="Dirección"
            fullWidth
            value={currentProfessional?.direccion || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="telefono"
            label="Teléfono"
            fullWidth
            value={currentProfessional?.telefono || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="correo_electronico"
            label="Correo Electrónico"
            fullWidth
            value={currentProfessional?.correo_electronico || ''}
            onChange={handleChange}
          />
        <FormControl fullWidth margin="dense">
          <InputLabel>Especialidad</InputLabel>
          <Select
            name="especialidad"
            value={currentProfessional?.especialidad || ''}
            onChange={handleChange}
          >
            <MenuItem value="Médico">Médico</MenuItem>
            <MenuItem value="Enfermería">Enfermería</MenuItem>
            <MenuItem value="Kinesiólogo">Kinesiólogo</MenuItem>
            <MenuItem value="Fonoaudiólogo">Fonoaudiólogo</MenuItem>
            <MenuItem value="Terapia Ocupacional">Terapia Ocupacional</MenuItem>
            <MenuItem value="Trabajador Social">Trabajador Social</MenuItem>
            <MenuItem value="TENS">TENS</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense">
          <InputLabel>Horarios de Trabajo</InputLabel>
          <Select
            name="horarios_trabajo"
            value={currentProfessional?.horarios_trabajo || ''}
            onChange={handleChange}
          >
            <MenuItem value="Diurno">Diurno</MenuItem>
            <MenuItem value="Cuarto Turno">Cuarto Turno</MenuItem>
          </Select>
        </FormControl>

        {/* <FormControl fullWidth margin="dense">
          <InputLabel>Coordinador</InputLabel>
          <Select
            name="coordinador"
            value={currentProfessional?.coordinador || ''}
            onChange={handleChange}
          >
            <MenuItem value="Coordinador A">Coordinador A</MenuItem>
            <MenuItem value="Coordinador B">Coordinador B</MenuItem>
            <MenuItem value="Coordinador C">Coordinador C</MenuItem>
          </Select>
        </FormControl> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleUpdateProfessional} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para agregar profesional */}
      <Dialog
        open={addModalOpen}
        //onClose={() => setAddModalOpen(false)}
        onClose={handleCloseAddModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Agregar Profesional</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="nombre"
            label="Nombre"
            fullWidth
            value={currentProfessional?.nombre || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="rut"
            label="RUT"
            fullWidth
            value={currentProfessional?.rut || ''}
            onChange={handleChange}

          />
          <TextField
            margin="dense"
            name="fecha_nacimiento"
            label="Fecha de Nacimiento"
            type='date'
            fullWidth
            value={currentProfessional?.fecha_nacimiento || ''}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}


          />

          <FormControl fullWidth margin="dense">
            <InputLabel>Género</InputLabel>
            <Select
              name="genero"
              value={currentProfessional?.genero || ''}
              onChange={handleChange}
            >
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="Femenino">Femenino</MenuItem>
              <MenuItem value="Otro">Otro</MenuItem>
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            name="direccion"
            label="Dirección"
            fullWidth
            value={currentProfessional?.direccion || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="telefono"
            label="Teléfono"
            fullWidth
            value={currentProfessional?.telefono || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="correo_electronico"
            label="Correo Electrónico"
            fullWidth
            value={currentProfessional?.correo_electronico || ''}
            onChange={handleChange}
          />

        <FormControl fullWidth margin="dense">
          <InputLabel>Especialidad</InputLabel>
          <Select
            name="especialidad"
            value={currentProfessional?.especialidad || ''}
            onChange={handleChange}
          >
            <MenuItem value="Médico">Médico</MenuItem>
            <MenuItem value="Enfermería">Enfermería</MenuItem>
            <MenuItem value="Kinesiólogo">Kinesiólogo</MenuItem>
            <MenuItem value="Fonoaudiólogo">Fonoaudiólogo</MenuItem>
            <MenuItem value="Terapia Ocupacional">Terapia Ocupacional</MenuItem>
            <MenuItem value="Trabajador Social">Trabajador Social</MenuItem>
            <MenuItem value="TENS">TENS</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense">
          <InputLabel>Horarios de Trabajo</InputLabel>
          <Select
            name="horarios_trabajo"
            value={currentProfessional?.horarios_trabajo || ''}
            onChange={handleChange}
          >
            <MenuItem value="Diurno">Diurno</MenuItem>
            <MenuItem value="Cuarto Turno">Cuarto Turno</MenuItem>
          </Select>
        </FormControl>

        {/* <FormControl fullWidth margin="dense">
          <InputLabel>Coordinador</InputLabel>
          <Select
            name="coordinador"
            value={currentProfessional?.coordinador || ''}
            onChange={handleChange}
          >
            <MenuItem value="Cord A">Cord A</MenuItem>
            <MenuItem value="Cord B">Cord B</MenuItem>
            <MenuItem value="Cord C">Cord C</MenuItem>
          </Select>
        </FormControl> */}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAddProfessional} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de confirmación de eliminación */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Está seguro que desea eliminar este profesional?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDelete} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="secondary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={responseModalOpen}
        onClose={() => setResponseModalOpen(false)}
        aria-labelledby="response-dialog-title"
      >
        <DialogTitle id="response-dialog-title">Mensaje</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {responseMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResponseModalOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default ProfessionalComponent;
