import React from "react";
import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Routes, Link  } from "react-router-dom";
import history from "./utils/history";
import Profile from "./views/Profile";
import PatientForm from "./views/PatientForm";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import GroupIcon from '@mui/icons-material/Group';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupsSharpIcon from '@mui/icons-material/GroupsSharp';
import Home from './components/Statistics';
import HomePage from './components/HomePage';
import CrudTablePatient from './components/CrudTablePatient';
import PatientAssignment from './components/PatientAssignment';
import CreateTeams from './components/CreateTeams';
import ProfessionalForm from './components/ProfessionalForm';
import CrudPatientAssignment from './components/CrudPatientAssignment';
import ProfessionalStatistics from './components/ProfessionalStatistics';
import ProfessionalPatientfile from './components/ProfessionalPatientfile';

const drawerWidth = 240;


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer() {

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const {user, isAuthenticated, loginWithRedirect, logout, isLoading,} = useAuth0();
  let profesion = '';
  let rol = '';
  const defaultPicture = '/static/images/default-avatar.png';

  if (isAuthenticated) {
    const roles = user['http://localhost:3000/roles'] || [];
    const metadata = user['http://localhost:3000/metadata'] || [];
    profesion = metadata;
    rol = roles[0];
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
   
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
  });
  return (
    <Router history={history}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
       <AppBar position="fixed" open={open}>
        <Toolbar>
        {isAuthenticated && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 0,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
          <Toolbar sx={{ flexGrow: 1 }}>
            <LocalHospitalIcon sx={{ mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#"
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 800,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                flexGrow: 1,
              }}
            >
              HSJD
            </Typography>

          </Toolbar>

        {isAuthenticated && (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={user?.picture || defaultPicture} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
            <MenuItem disabled>
              <Typography
                textAlign="center"
                variant="h6"
              >
                {user.name}
              </Typography>
            </MenuItem>

            <MenuItem onClick={handleCloseUserMenu} to="/profile" component={Link}>
            <Typography 
                textAlign="center"
                variant="overline"
              >
                Perfil
              </Typography>
            </MenuItem>

            <MenuItem onClick={handleCloseUserMenu}>
              <Typography 
                textAlign="center"
                variant="overline"
                onClick={() => logoutWithRedirect()}
              >
                Salir
              </Typography>
            </MenuItem>

            </Menu>
          </Box>
        )}

        {!isAuthenticated && (
          <Button color="inherit" onClick={() => loginWithRedirect()}>Inicio</Button>
        )}

        </Toolbar>
      </AppBar> 

      {isAuthenticated && (
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

          <List>

          {(isAuthenticated) && (
            <ListItem key={'Gestión pacientes'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton to="/GestionPacientes" component={Link} sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5,}}>
                <ListItemIcon to="/GestionPacientes" component={Link} sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center',}}>
                <GroupIcon/>
                </ListItemIcon>
                <ListItemText primary={'Gestión pacientes'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}
          {(rol === 'Admin' && isAuthenticated) && (
            <ListItem key={'Gestión profesionales'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton to="/ProfessionalForm" component={Link} sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5,}}>
                <ListItemIcon to="/ProfessionalForm" component={Link} sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center',}}>
                <MedicalInformationIcon/>
                </ListItemIcon>
                <ListItemText primary={'Gestión profesionales'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}
          {(isAuthenticated) && (
            <ListItem key={'Gestión de equipos'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton to="/CreateTeams" component={Link} sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5,}}>
                <ListItemIcon to="/CreateTeams" component={Link} sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center',}}>
                  <GroupsSharpIcon/>
                </ListItemIcon>
                <ListItemText primary={'Gestión de equipos'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}
          {(isAuthenticated) && (
            <ListItem key={'Asignación médica'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton to="/CrudPatientAssignment" component={Link} sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5,}}>
                <ListItemIcon to="/CrudPatientAssignment" component={Link} sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center',}}>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary={'Asignación médica'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          
        )}
        {(rol === 'Admin' && isAuthenticated) && ( <Divider /> )}
            <ListItem key={'Gestión de datos'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton to="/ProfessionalStatistics" component={Link} sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5,}}>
                <ListItemIcon to="/ProfessionalStatistics" component={Link} sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center',}}>
                <QueryStatsIcon />
                </ListItemIcon>
                <ListItemText primary={'Gestión de datos'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>

      </Drawer>
      )}
      {isAuthenticated && (
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader/>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/PatientAssignment" element={<PatientAssignment/>} /> 
            <Route path="/Profile" element={<Profile/>} />
            <Route path="/PatientForm" element={<PatientForm/>} />
            <Route path="/CreateTeams" element={<CreateTeams/>} />
            <Route path="/GestionPacientes" element={<CrudTablePatient/>} />
            <Route path="/ProfessionalForm" element={<ProfessionalForm/>} />
            <Route path="/ProfessionalStatistics" element={<Home/>} />
            <Route path="/CrudPatientAssignment" element={<CrudPatientAssignment/>} />
            <Route path="/ProfessionalPatientfile/:id" element={<ProfessionalPatientfile/>} />
          </Routes>
      </Box>
      )}
      {!isAuthenticated && (
        <Box component="main" sx={{ flexGrow: 1}}>
          <DrawerHeader/>
          <HomePage/>
        </Box>
      )}
    </Box>
    </Router>
  );
}