// src/theme.js
import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto'; // Import Roboto font

const theme = createTheme({
  palette: {
    primary: {
      main: '#002A2E',
    },
    secondary: {
      main: '#002428',
    },
  },
  spacing: 10, // Define the base spacing unit
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  body1: {
    color: '#135D66', // Custom color for body1
  },
});

export default theme;