import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Box,
  TableSortLabel,
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { Link } from 'react-router-dom';

const apiUrlProfessionals = 'https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-profesionales?estado_equipo=NO_ASIGNADO';
const apiUrlTeams = 'https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-equipos';
const apiUrlCreateTeams = 'https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/ingreso-equipo';
const apiUrlDeleteTeam = 'https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/eliminar-equipo?equipo_id=';
const apiUrlEditTeam = 'https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/actualizar-equipo';

const CreateTeam = () => {
  const [professionals, setProfessionals] = useState([]);
  const [selectedProfessionals, setSelectedProfessionals] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamName, setTeamName] = useState('');
  const [teamDescription, setTeamDescription] = useState('');
  const [orderBy, setOrderBy] = useState('nombre');
  const [order, setOrder] = useState('asc');
  const [selectedRows, setSelectedRows] = useState([]);
  const [existingTeams, setExistingTeams] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);
  const [editTeamName, setEditTeamName] = useState('');
  const [editTeamDescription, setEditTeamDescription] = useState(''); // Añadir estado para la descripción
  const [editTeamId, setEditTeamId] = useState(null);
  const [openRemoveProfessionalDialog, setOpenRemoveProfessionalDialog] = useState(false);
  const [professionalToRemove, setProfessionalToRemove] = useState(null);
  const [openSpecialtyModal, setOpenSpecialtyModal] = useState(false);
  const [duplicateSpecialty, setDuplicateSpecialty] = useState('');
  const {user, isAuthenticated, loginWithRedirect, logout, isLoading,} = useAuth0();
  const [expanded, setExpanded] = useState({});
  let profesion = '';
  let rol = '';

  if (isAuthenticated) {
    const roles = user['http://localhost:3000/roles'] || [];
    const metadata = user['http://localhost:3000/metadata'] || [];
    profesion = metadata;
    rol = roles[0];
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({
      ...expanded,
      [panel]: isExpanded,
    });
  };

  const fetchProfessionals = async () => {
    try {
      const response = await axios.get(apiUrlProfessionals);
      setProfessionals(response.data);
    } catch (error) {
      console.error('Error fetching professionals:', error);
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await axios.get(apiUrlTeams);
      setExistingTeams(response.data);
      const initialExpandedState = response.data.reduce((acc, team) => {
        acc[team.equipo_id] = true; // Aquí asumimos que todos los acordeones deben estar abiertos por defecto
        return acc;
      }, {});
      setExpanded(initialExpandedState);
    } catch (error) {
      console.error('Error fetching teams:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfessionals();
    fetchTeams();
  }, []);

const handleSelectProfessional = (professional) => {
  const isSelected = selectedProfessionals.some(p => p.profesional_id === professional.profesional_id);
  const hasSameSpecialty = selectedProfessionals.some(p => p.especialidad === professional.especialidad);

  if (!isSelected && !hasSameSpecialty) {
    setSelectedProfessionals([...selectedProfessionals, professional]);
    setSelectedRows([...selectedRows, professional.profesional_id]);
  } else if (isSelected) {
    setSelectedProfessionals(selectedProfessionals.filter(p => p.profesional_id !== professional.profesional_id));
    setSelectedRows(selectedRows.filter(id => id !== professional.profesional_id));
  } else if (hasSameSpecialty) {
    setDuplicateSpecialty(professional.especialidad);
    setOpenSpecialtyModal(true);
  }
};

  const handleRemoveProfessional = (professional) => {
    setProfessionalToRemove(professional);
    setOpenRemoveProfessionalDialog(true);
  };

  const confirmRemoveProfessional = () => {
    setSelectedProfessionals(selectedProfessionals.filter(p => p.profesional_id !== professionalToRemove.profesional_id));
    setSelectedRows(selectedRows.filter(id => id !== professionalToRemove.profesional_id));
    setOpenRemoveProfessionalDialog(false);
    setProfessionalToRemove(null);
  };

  const handleCreateTeam = async () => {
    if (selectedProfessionals.length > 0) {
      const data = {
        "coordinador_id": "1",
        "nombre": teamName,
        "descripcion": teamDescription, // Descripción del equipo
        "profesionales": selectedProfessionals
      };

      try {
        const response = await axios.post(apiUrlCreateTeams, data);
        console.log('Respuesta servicio', response);
        setSelectedProfessionals([]);
        setSelectedRows([]);
        setTeamName('');
        setTeamDescription('');

        await fetchProfessionals();
        await fetchTeams();
      } catch (error) {
        console.error('Error creating team:', error);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedProfessionals = () => {
    const comparator = (a, b) => {
      if (order === 'asc') {
        return a[orderBy].localeCompare(b[orderBy]);
      } else {
        return b[orderBy].localeCompare(a[orderBy]);
      }
    };
    return professionals.sort(comparator);
  };

  const renderProfessionalsTable = () => (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'nombre'}
                direction={orderBy === 'nombre' ? order : 'asc'}
                onClick={() => handleRequestSort('nombre')}
              >
                Nombre
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'especialidad'}
                direction={orderBy === 'especialidad' ? order : 'asc'}
                onClick={() => handleRequestSort('especialidad')}
              >
                Especialidad
              </TableSortLabel>
            </TableCell>
            <TableCell>Seleccionar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedProfessionals().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((professional) => (
            <TableRow
              key={professional.profesional_id}
              sx={{ backgroundColor: selectedRows.includes(professional.profesional_id) ? '#E0E0E0' : 'inherit' }}
            >
              <TableCell>{professional.nombre}</TableCell>
              <TableCell>{professional.especialidad}</TableCell>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedProfessionals.some(p => p.profesional_id === professional.profesional_id)}
                  onChange={() => handleSelectProfessional(professional)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        rowsPerPageOptions={[10, 15]}
        component="div"
        count={sortedProfessionals().length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );

  const handleOpenDeleteDialog = (team) => {
    setTeamToDelete(team);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setTeamToDelete(null);
  };

  const handleDeleteTeam = async () => {
    if (teamToDelete) {
      try {
        await axios.delete(`${apiUrlDeleteTeam}${teamToDelete.equipo_id}`);
        setExistingTeams(existingTeams.filter(team => team.equipo_id !== teamToDelete.equipo_id));
        handleCloseDeleteDialog();
      } catch (error) {
        console.error('Error deleting team:', error);
      }
    }
  };

  const handleOpenEditDialog = (team) => {
    setEditTeamName(team.equipo_nombre);
    setEditTeamDescription(team.descripcion); // Añadir descripción
    setEditTeamId(team.equipo_id);
  };

  const handleEditTeam = async () => {
    if (editTeamId && editTeamName) {
      try {
        await axios.post(apiUrlEditTeam, {
          equipo_id: editTeamId,
          equipo_nombre: editTeamName,
          descripcion: editTeamDescription, // Incluir descripción en la solicitud
        });
        setExistingTeams(existingTeams.map(team => team.equipo_id === editTeamId ? { ...team, equipo_nombre: editTeamName, descripcion: editTeamDescription } : team));
        setEditTeamId(null);
        setEditTeamName('');
        setEditTeamDescription(''); // Limpiar descripción
      } catch (error) {
        console.error('Error editing team:', error);
      }
    }
  };

  return (
    <Box sx={{ width: '100%', boxShadow: 6, padding: 2 }}>
      <Box
        sx={{
          backgroundColor: '#003C43',
          p: 1,
          display: 'flex',
          padding: 1,
          borderRadius: 1,
          marginBottom: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" color="white" sx={{ marginLeft: 6 }}>
          Gestión de equipos para atención
        </Typography>
        {(rol === 'Admin' && isAuthenticated) && (
        <Link to="/ProfessionalForm" align="start">
          <Button
            variant="contained"
            sx={{ backgroundColor: '#003C43', color: 'white', '&:hover': { backgroundColor: '#134566' } }}
          >
            Ingresar Profesional
          </Button>
        </Link>)}
      </Box>
      {(rol === 'Admin' && isAuthenticated) && (
        renderProfessionalsTable()
      )}

      {(rol === 'Admin' && isAuthenticated) && (
      <Paper sx={{ p: 2, marginTop: 3 }}>
        <Typography variant="h6" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
          Equipo Actual
        </Typography>
        <List>
          {selectedProfessionals.map((professional) => (
            <ListItem key={professional.profesional_id}>
              <ListItemText primary={professional.nombre} />
              <ListItemText primary={professional.especialidad} />
              <Button onClick={() => handleRemoveProfessional(professional)}>
                <DeleteIcon />
              </Button>
            </ListItem>
          ))}
        </List>

        <TextField
          fullWidth
          label="Nombre del equipo"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          sx={{ marginTop: 2 }}
        />
        <TextField
          fullWidth
          label="Descripción del equipo"
          value={teamDescription}
          onChange={(e) => setTeamDescription(e.target.value)}
          sx={{ marginTop: 2 }}
        />

        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateTeam}
            disabled={selectedProfessionals.length === 0}
          >
            Crear Equipo
          </Button>
        </FormControl>
      </Paper>)}

      <Paper sx={{ p: 2, marginTop: 3 }}>
        <Typography variant="h6" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
          Equipos Creados
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : existingTeams.length > 0 ? (
          existingTeams.map((team) => (
            <Accordion expanded={expanded[team.equipo_id]} onChange={handleChange(team.equipo_id)} key={team.equipo_id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1" >{team.equipo_nombre}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: '#f7f7f7' }}>
                <Typography variant="subtitle2" sx={{ marginTop: 1 }}>Descripción: {team.descripcion}</Typography>
                <Typography variant="subtitle2" sx={{ marginTop: 1, marginBottom: 2 }}>Profesionales: </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Rut</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Especialidad</TableCell>
                      {(rol === 'Admin' && isAuthenticated) && (<TableCell>Acciones</TableCell>)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {team.profesionales.map((professional) => (
                      <TableRow key={professional.profesional_id}>
                      <TableCell>{professional.rut}</TableCell>
                        <TableCell>{professional.nombre}</TableCell>
                        <TableCell>{professional.especialidad}</TableCell>
                        {(rol === 'Admin' && isAuthenticated) && (
                        <TableCell padding="checkbox">
                          <IconButton onClick={() => handleRemoveProfessional(professional)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>)}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {(rol === 'Admin' && isAuthenticated) && (
                <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<EditIcon />}
                      onClick={() => handleOpenEditDialog(team)}
                      sx={{ marginRight: 1 }}
                    >
                      Editar Equipo
                  </Button>
                  <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleOpenDeleteDialog(team)}
                    >
                      Eliminar Equipo
                  </Button>
                </Box>)}

              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography align="center">No hay equipos creados.</Typography>
        )}
      </Paper>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Eliminar Equipo</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Está seguro de que desea eliminar este equipo?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteTeam} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSpecialtyModal} onClose={() => setOpenSpecialtyModal(false)}>
        <DialogTitle>Nota</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ya has seleccionado un profesional con la especialidad "{duplicateSpecialty}". No se pueden seleccionar dos profesionales con la misma especialidad.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSpecialtyModal(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!editTeamId} onClose={() => setEditTeamId(null)}>
        <DialogTitle>Editar Equipo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre del Equipo"
            fullWidth
            value={editTeamName}
            onChange={(e) => setEditTeamName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Descripción del Equipo"
            fullWidth
            value={editTeamDescription}
            onChange={(e) => setEditTeamDescription(e.target.value)} // Añadir campo para descripción
            sx={{ marginTop: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditTeamId(null)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleEditTeam} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openRemoveProfessionalDialog} onClose={() => setOpenRemoveProfessionalDialog(false)}>
        <DialogTitle>Eliminar Profesional</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Está seguro de que desea eliminar este profesional del equipo?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRemoveProfessionalDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmRemoveProfessional} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateTeam;
