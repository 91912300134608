import React from 'react';
import {
  Box,
  Typography,
  Grid,
} from '@mui/material';
import LaptopIcon from '@mui/icons-material/Laptop';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material/styles';

const IconBox = styled(Box)(({ theme, shadowColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  width: '270px',
  height: '270px',
  borderRadius: '5%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: `0 4px 8px 0 #002A2E, 0 6px 20px 0 #002A2E`,
}));

const textShadowStyle = {
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)'
};

const HomePage = () => {
  return (
    <Box sx={{ width: '100%', padding: 1, marginTop: 6 }}>
      {/* <Box sx={{ width: '100%', textAlign: 'center', marginBottom: 4, padding: 1}}>
        <Typography variant="h6" sx={textShadowStyle} >Gestión de pacientes de la unidad domiciliara del hospital San Juan de Dios</Typography>
      </Box> */}

      <Grid container spacing={4} justifyContent="center">
        {/* Laptop Section */}
        <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
          <IconBox>
            <LaptopIcon color="primary" fontSize="large" />
            <Typography variant="h6" align="center" >Gestión en Línea</Typography>
            <Typography variant="body2" align="center">
              Acceso a la información de los pacientes y reportes médicos desde cualquier lugar.
            </Typography>
          </IconBox>
        </Grid>

        {/* Files Section */}
        <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
          <IconBox>
            <FileCopyIcon color="primary" fontSize="large" />
            <Typography variant="h6" align="center" >Documentación</Typography>
            <Typography variant="body2" align="center">
              Manejo eficiente de la documentación médica y administrativa.
            </Typography>
          </IconBox>
        </Grid>

        {/* Charts Section */}
        <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
          <IconBox>
            <BarChartIcon color="primary" fontSize="large" />
            <Typography variant="h6" align="center" >Estadísticas</Typography>
            <Typography variant="body2" align="center">
              Análisis de datos y reportes para un mejor seguimiento.
            </Typography>
          </IconBox>
        </Grid>

        {/* Assignments Section */}
        <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
          <IconBox>
            <AssignmentIcon color="primary" fontSize="large" />
            <Typography variant="h6" align="center">Tareas Asignadas</Typography>
            <Typography variant="body2" align="center">
              Gestión y seguimiento de las tareas asignadas al equipo médico.
            </Typography>
          </IconBox>
        </Grid>

        {/* User Profile Section */}
        <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
          <IconBox>
            <AccountCircleIcon color="primary" fontSize="large" />
            <Typography variant="h6" align="center">Perfil del Usuario</Typography>
            <Typography variant="body2" align="center">
              Información y gestión del perfil de cada paciente.
            </Typography>
          </IconBox>
        </Grid>
      </Grid>
      {/* Footer Section */}

    </Box>
  );
};

export default HomePage;
