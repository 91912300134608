import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  TextField,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';

const colors = {
  primary: "#003C43",
  light: "#E3FEF7",
  additional4: "#FEE3EA"
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const ProfessionalPatientFile = () => {
  const location = useLocation();
  const assignment = location.state?.assignment || {};

  const [closeVisitModalOpen, setCloseVisitModalOpen] = useState(false);
  const [closeVisitData, setCloseVisitData] = useState({
    estado: '',
    observacion: ''
  });

  const [tabValue, setTabValue] = useState(0);

  const [formData, setFormData] = useState({
    medico: assignment.ficha?.medico?.registros || {
      historiaClinica: '',
      controlSignosVitales: '',
      evolucion: '',
      observaciones: '',
      diagnostico: '',
      indicaciones: ''
    },
    enfermeria: assignment.ficha?.enfermeria?.registros || {
      controlSignosVitales: '',
      evolucion: '',
      observaciones: '',
      plan: '',
      indicaciones: ''
    },
    kinesiologo: assignment.ficha?.kinesiologia?.registros || {
      controlSignosVitales: '',
      evolucion: '',
      diagnostico: '',
      indicaciones: ''
    },
    fonoaudiologo: assignment.ficha?.fonoaudiologia?.registros || {
      controlSignosVitales: '',
      evolucion: '',
      diagnostico: '',
      indicaciones: ''
    },
    terapiaOcupacional: assignment.ficha?.terapiaOcupacional?.registros || {
      controlSignosVitales: '',
      evolucion: '',
      diagnostico: '',
      indicaciones: '',
      observaciones: ''
    },
    tens: assignment.ficha?.tens?.registros || {
      controlSignosVitales: '',
      evolucion: '',
      observaciones: ''
    },
    trabajadorSocial: assignment.ficha?.trabajoSocial?.registros || {
      evolucion: '',
      observaciones: ''
    }
  });

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (event, category) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [category]: {
        ...formData[category],
        [name]: value
      }
    });
  };

  const handleSubmit = async (category) => {
    const data = {
      "paciente_id": assignment.paciente_id,
      "fecha": assignment.fecha,
      "equipo_medico": assignment.equipo_medico,
      "profesional": category,
      "datos": formData[category]
    };

    console.log('Datos a enviar:', JSON.stringify(data, null, 2));
    // Aquí se puede enviar `data` a la API si es necesario
  };

  const handleCloseVisitSubmit = async () => {
    const data = {
      "paciente_id": assignment.paciente_id,
      "fecha": assignment.fecha,
      "estado": closeVisitData.estado,
      "observacion": closeVisitData.observacion,
      "fecha_cierre": new Date().toISOString(),
      "proceso": "CIERRE"
    };

    console.log('Datos para cerrar visita:', JSON.stringify(data, null, 2));
    setCloseVisitModalOpen(false);
    // Aquí se puede enviar `data` a la API si es necesario
  };

  const renderTextField = (label, name, value, category) => (
    <TextField
      key={`${category}-${name}`}
      label={label}
      name={name}
      value={value}
      onChange={(e) => handleChange(e, category)}
      multiline
      fullWidth
      margin="normal"
    />
  );

  const renderTabContent = (category, fields) => (
    <div>
      {fields.map(({ label, name }) =>
        renderTextField(label, name, formData[category][name], category)
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(category)}
          sx={{ minWidth: '100px' }}
        >
          Confirmar
        </Button>
      </Box>
    </div>
  );

  const tabs = [
    { label: "Médico", category: "medico", fields: [
        { label: "Historia Clínica", name: "historiaClinica" },
        { label: "Control Signos Vitales", name: "controlSignosVitales" },
        { label: "Evolución", name: "evolucion" },
        { label: "Observaciones", name: "observaciones" },
        { label: "Diagnóstico", name: "diagnostico" },
        { label: "Indicaciones", name: "indicaciones" }
      ]
    },
    { label: "Enfermería", category: "enfermeria", fields: [
        { label: "Control Signos Vitales", name: "controlSignosVitales" },
        { label: "Evolución", name: "evolucion" },
        { label: "Observaciones", name: "observaciones" },
        { label: "Plan", name: "plan" },
        { label: "Indicaciones", name: "indicaciones" }
      ]
    },
    { label: "Kinesiólogo", category: "kinesiologo", fields: [
        { label: "Control Signos Vitales", name: "controlSignosVitales" },
        { label: "Evolución", name: "evolucion" },
        { label: "Diagnóstico", name: "diagnostico" },
        { label: "Indicaciones", name: "indicaciones" }
      ]
    },
    { label: "Fonoaudiólogo", category: "fonoaudiologo", fields: [
        { label: "Control Signos Vitales", name: "controlSignosVitales" },
        { label: "Evolución", name: "evolucion" },
        { label: "Diagnóstico", name: "diagnostico" },
        { label: "Indicaciones", name: "indicaciones" }
      ]
    },
    { label: "Terapia Ocupacional", category: "terapiaOcupacional", fields: [
        { label: "Control Signos Vitales", name: "controlSignosVitales" },
        { label: "Evolución", name: "evolucion" },
        { label: "Diagnóstico", name: "diagnostico" },
        { label: "Indicaciones", name: "indicaciones" },
        { label: "Observaciones", name: "observaciones" }
      ]
    },
    { label: "Tens", category: "tens", fields: [
        { label: "Control Signos Vitales", name: "controlSignosVitales" },
        { label: "Evolución", name: "evolucion" },
        { label: "Observaciones", name: "observaciones" }
      ]
    },
    { label: "Trabajador Social", category: "trabajadorSocial", fields: [
        { label: "Evolución", name: "evolucion" },
        { label: "Observaciones", name: "observaciones" }
      ]
    },
  ];

  return (
    <Container>
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h4" sx={{ marginBottom: 1 }}>Información del Paciente</Typography>
        <Typography variant="body1"><strong>ID:</strong> {assignment.paciente_id}</Typography>
        <Typography variant="body1"><strong>Nombre:</strong> {assignment.nombre}</Typography>
        <Typography variant="body1"><strong>Equipo Médico:</strong> {`Equipo ${assignment.equipo_medico}`}</Typography>
        <Typography variant="body1"><strong>Fecha de Asignación:</strong> {assignment.fecha}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setCloseVisitModalOpen(true)}
          >
            Cerrar Visita
          </Button>
        </Box>
      </Box>

      <AppBar position="static" sx={{ backgroundColor: colors.primary, borderRadius: 1 }}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="Professional Tabs"
          textColor="inherit"
          TabIndicatorProps={{ style: { backgroundColor: colors.additional4 } }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} sx={{ color: colors.light }} />
          ))}
        </Tabs>
      </AppBar>

      {tabs.map((tab, index) => (
        <TabPanel key={index} value={tabValue} index={index} sx={{ border: `2px solid ${colors.additional4}`, borderRadius: 1 }}>
          {renderTabContent(tab.category, tab.fields)}
        </TabPanel>
      ))}

      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 3 }}>
        <Link to="/CrudPatientAssignment" style={{ textDecoration: 'none' }}>
          <Button variant="contained" sx={{ backgroundColor: '#77B0AA', color: 'white', '&:hover': { backgroundColor: '#77B0AA' }, minWidth: '80px' }}>
            Volver
          </Button>
        </Link>
      </Box>

      <Dialog
        open={closeVisitModalOpen}
        onClose={() => setCloseVisitModalOpen(false)}
        aria-labelledby="close-visit-dialog-title"
      >
        <DialogTitle id="close-visit-dialog-title">Cerrar Visita</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel>Estado</InputLabel>
            <Select
              name="estado"
              value={closeVisitData.estado}
              onChange={(e) => setCloseVisitData({ ...closeVisitData, estado: e.target.value })}
            >
              <MenuItem value="completada">Completada</MenuItem>
              <MenuItem value="cancelada">Cancelada</MenuItem>
              <MenuItem value="pendiente">Pendiente</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Observación"
            fullWidth
            multiline
            rows={4}
            value={closeVisitData.observacion}
            onChange={(e) => setCloseVisitData({ ...closeVisitData, observacion: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCloseVisitModalOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCloseVisitSubmit} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProfessionalPatientFile;
