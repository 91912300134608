import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import {  Dialog, DialogActions, DialogContent, DialogTitle,   Paper,  Typography,  Table,  TableBody,
  TableCell,  TableContainer,  TableHead,  TableRow,  Button,  Box,  TextField,  TablePagination,
  IconButton,  Modal,  Backdrop,  Fade,  Tooltip,  Chip,  FormControl,  InputLabel,  Select,  MenuItem
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import NotesIcon from '@mui/icons-material/Notes';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import EditIcon from '@mui/icons-material/Edit';
import jsPDF from 'jspdf';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const apiUrlAssignedPatients = 'https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-asignacion-pacientes';
const apiUrlUpdateAssignmentStatus = 'https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/actualizar-estado-asignacion-paciente';


const AssignmentHistory = () => {
  const [assignedPatients, setAssignedPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {user, isAuthenticated, loginWithRedirect, logout, isLoading} = useAuth0();
  const [filters, setFilters] = useState({
    nombre: '',
    equipo: '',
    estado_atencion: '',
    fecha: ''
  });
  const [openModal, setOpenModal] = useState(false);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  //const [resumeData, setResumeData] = useState(null);

  const [resumeData, setResumeData] = useState({
    estadoFinal: '',
    motivoRetiro: '', // Añadir el estado para el motivo de retiro
  });

  let user_rol = '';
  let user_rut = '';
  let user_profesion = '';

  if (isAuthenticated) {
    const roles = user['http://localhost:3000/roles'] || [];
    const metadata = user['http://localhost:3000/metadata'] || [];
    user_profesion = metadata;
    user_rol = roles[0];
    user_rut = roles[1];
  }

  const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
  const navigate = useNavigate();
  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const [selectedSuspendAssignmentId, setSelectedSuspendAssignmentId] = useState(null);
  const [selectedAssignment, setSelectedAssignment] = useState({
    enfermeria: {},
    medico: {},
    fonoaudiologo: {},
    kinesiologo: {},
    tens: {},
    terapiaOcupacional: {},
    trabajoSocial: {},
  });

  const userRole = 'profesional';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedField, setSelectedField] = useState('');

  const professionMap = {
    'médico': 'medico',
    'enfermería': 'enfermeria',
    'fonoaudiología': 'fonoaudiologo',
    'kinesiología': 'kinesiologo',
    'tens': 'tens',
    'terapia ocupacional': 'terapiaOcupacional',
    'trabajador social': 'trabajoSocial',
  };

  const fetchAssignedPatients = async () => {
    try {
      const response = await axios.get(apiUrlAssignedPatients);
      setAssignedPatients(response.data);
      setFilteredPatients(response.data);
    } catch (error) {
      console.error('Error fetching assigned patients:', error);
    }
  };

  useEffect(() => {
    fetchAssignedPatients();
  }, []);

  useEffect(() => {
    const filtered = assignedPatients.filter(patient => {
      const matchNombre = (patient.nombre || '').toLowerCase().includes(filters.nombre.toLowerCase());
      const matchEquipo = (`Equipo ${patient.equipo_medico}` || '').toLowerCase().includes(filters.equipo.toLowerCase());
      const matchEstado = (patient.estado_atencion || '').toLowerCase().includes(filters.estado_atencion.toLowerCase());
      const matchFecha = (patient.fecha || '').includes(filters.fecha);

      return matchNombre && matchEquipo && matchEstado && matchFecha;
    });
    setFilteredPatients(filtered);
  }, [filters, assignedPatients]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleIconClick = (assignment, field) => {
    const professionKey = professionMap[user_profesion.toLowerCase()];
    console.log("professionKey: ", user_profesion)
    console.log("professionMap[user_profesion.toLowerCase()]: ", professionMap[user_profesion.toLowerCase()])
    console.log("field:  ", field)

    if (user_profesion === field) {
      setSelectedAssignment(assignment);
      setSelectedField(field);
      setIsEditing(true);
      setIsModalOpen(true);
    } else {
      setIsEditing(false);
      setSelectedAssignment(assignment);
      setSelectedField(field);
      setIsModalOpen(true);
    }
  };

  const handleFieldChange = (field, subField, value) => {
    const professionKey = professionMap[user_profesion.toLowerCase()];
    
    if (professionKey === field.toLowerCase()) {
      setSelectedAssignment((prev) => {
        const updatedFicha = {
          ...prev.ficha,
          [field]: {
            ...prev.ficha[field],
            registros: {
              ...prev.ficha[field].registros,
              [subField]: value,
            },
          },
        };
        return {
          ...prev,
          ficha: updatedFicha,
        };
      });
    }
  };

  const handleCloseFicha = async () => {
    const cierreData = {
      ...resumeData,
      fecha_cierre: new Date().toISOString(),
      motivo_retiro: resumeData.estadoFinal === 'Retiro' ? resumeData.motivoRetiro : '', // Verificar si existe motivoRetiro
    };

    console.log("cierreData ", cierreData)
    const data = {
      "paciente_id": cierreData.paciente_id,
      "fecha": cierreData.fecha,
      "estado": cierreData.estadoFinal,
      "motivo_retiro": cierreData.motivo_retiro,
      "observacion": cierreData.observacionFinal,
      "fecha_cierre": cierreData.fecha_cierre,
      "proceso": "CIERRE"
    };

    const response = await axios.post('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/completar-ficha-paciente', data);

    await fetchAssignedPatients();

    setIsResumeModalOpen(false);
  };

  const suspendAssignment = (assignmentId) => {
    setSelectedSuspendAssignmentId(assignmentId);
    setOpenSuspendModal(true);
  };

  const viewAssignment = (assignment) => {
    navigate(`/ProfessionalPatientFile/${assignment.paciente_id}`, { state: { assignment } });
  };

  const resumeAssignment = async (assignment) => {
    try {
      const response = await axios.get(`https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/ver-ficha-paciente?paciente_id=${assignment.paciente_id}&fecha=${assignment.fecha}`);
      const data = response.data;
      setResumeData(data);
      setIsResumeModalOpen(true);
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    let yPos = 10;
    const lineHeight = 5;
    const lineHeightlin = 10;
    const pageHeight = doc.internal.pageSize.height;

    const addSection = (title, content) => {
      if (!title || !Array.isArray(content)) return;

      if (yPos > 10) {
        doc.line(10, yPos, 200, yPos);
        yPos += lineHeightlin / 2;
      }

      doc.setFontSize(14);
      doc.setFont('times', 'bold');
      doc.setTextColor(0, 0, 255);
      const titleWidth = doc.getTextWidth(title);
      doc.text(title, (pageWidth - titleWidth) / 2, yPos);
      yPos += lineHeight - 2;

      if (yPos > 10) {
        doc.line(10, yPos, 200, yPos);
        yPos += lineHeightlin;
      }

      doc.setFontSize(12);
      doc.setFont('times', 'normal');
      doc.setTextColor(0, 0, 0);
      content.forEach((text) => {
        if (!text) return;

        const [label, value] = text.split(': ');

        if (label && value) {
          doc.setFont('times', 'bold');
          doc.text(`${label}:`, 10, yPos);

          doc.setFont('times', 'normal');
          const labelWidth = doc.getTextWidth(`${label}:`);
          doc.text(value, 10 + labelWidth + 5, yPos);

          yPos += lineHeight;

          if (yPos > pageHeight - lineHeight) {
            doc.addPage();
            yPos = 10;
          }
        }
      });
      yPos += lineHeight;
    };

    doc.setFontSize(18);
    doc.setFont('times', 'bold');
    doc.setTextColor(0, 0, 0);
    const header = 'Informe de Paciente';
    const headerWidth = doc.getTextWidth(header);
    doc.text(header, (pageWidth - headerWidth) / 2, yPos);
    yPos += lineHeight;

    addSection('Datos del Paciente', [
      `Nombre: ${resumeData?.nombre || ''}`,
      `RUT: ${resumeData?.rut || ''}`,
      `Dirección: ${resumeData?.direccion || ''}`
    ]);

    addSection('Estado de la Asignación', [
      `Estado Asignación: ${resumeData?.estado_asignacion || ''}`,
      `Estado Atención: ${resumeData?.estado_atencion || ''}`,
      `Fecha: ${resumeData?.fecha || ''}`
    ]);

    addSection('Enfermería', [
      `Control Signos Vitales: ${resumeData?.enfermeria?.controlSignosVitales || ''}`,
      `Evolución: ${resumeData?.enfermeria?.evolucion || ''}`,
      `Indicaciones: ${resumeData?.enfermeria?.indicaciones || ''}`,
      `Observaciones: ${resumeData?.enfermeria?.observaciones || ''}`,
      `Plan: ${resumeData?.enfermeria?.plan || ''}`
    ]);

    addSection('Médico', [
      `Control Signos Vitales: ${resumeData?.medico?.controlSignosVitales || ''}`,
      `Diagnóstico: ${resumeData?.medico?.diagnostico || ''}`,
      `Evolución: ${resumeData?.medico?.evolucion || ''}`,
      `Historia Clínica: ${resumeData?.medico?.historiaClinica || ''}`,
      `Indicaciones: ${resumeData?.medico?.indicaciones || ''}`,
      `Observaciones: ${resumeData?.medico?.observaciones || ''}`
    ]);

    addSection('Fonoaudiólogo', [
      `Control Signos Vitales: ${resumeData?.fonoaudiologo?.controlSignosVitales || ''}`,
      `Diagnóstico: ${resumeData?.fonoaudiologo?.diagnostico || ''}`,
      `Evolución: ${resumeData?.fonoaudiologo?.evolucion || ''}`,
      `Indicaciones: ${resumeData?.fonoaudiologo?.indicaciones || ''}`
    ]);

    addSection('Kinesiólogo', [
      `Control Signos Vitales: ${resumeData?.kinesiologo?.controlSignosVitales || ''}`,
      `Diagnóstico: ${resumeData?.kinesiologo?.diagnostico || ''}`,
      `Evolución: ${resumeData?.kinesiologo?.evolucion || ''}`,
      `Indicaciones: ${resumeData?.kinesiologo?.indicaciones || ''}`
    ]);

    addSection('TENS', [
      `Control Signos Vitales: ${resumeData?.tens?.controlSignosVitales || ''}`,
      `Evolución: ${resumeData?.tens?.evolucion || ''}`,
      `Observaciones: ${resumeData?.tens?.observaciones || ''}`
    ]);

    addSection('Terapia Ocupacional', [
      `Control Signos Vitales: ${resumeData?.terapiaOcupacional?.controlSignosVitales || ''}`,
      `Diagnóstico: ${resumeData?.terapiaOcupacional?.diagnostico || ''}`,
      `Evolución: ${resumeData?.terapiaOcupacional?.evolucion || ''}`,
      `Indicaciones: ${resumeData?.terapiaOcupacional?.indicaciones || ''}`,
      `Observaciones: ${resumeData?.terapiaOcupacional?.observaciones || ''}`
    ]);

    addSection('Trabajador Social', [
      `Evolución: ${resumeData?.trabajoSocial?.evolucion || ''}`,
      `Observaciones: ${resumeData?.trabajoSocial?.observaciones || ''}`
    ]);

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(`Página ${i} de ${pageCount}`, 180, pageHeight - 10);
    }

    doc.save(`Ficha_Paciente_${resumeData.rut}_${resumeData.fecha}.pdf`);
  };

  const handleSave = async (estado, profesion) => {
    const updatedAssignment = { ...selectedAssignment };
    updatedAssignment.estado_ficha[selectedField] = estado;

    const profesionKey = professionMap[profesion.toLowerCase()];

    const datos = updatedAssignment.ficha[profesionKey].registros;

    const data = {
      "paciente_id": updatedAssignment.paciente_id,
      "fecha": updatedAssignment.fecha,
      "equipo_medico": updatedAssignment.equipo_medico,
      "profesional": profesion,
      "profesionKey": profesionKey,
      "datos": datos,
      "estado": estado
    };

    const response = await axios.post('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/completar-ficha-paciente', data);

    await fetchAssignedPatients();

    setSelectedAssignment(updatedAssignment);
    setIsModalOpen(false);
  };

  const changeAssignmentStatus = (assignmentId) => {
    setSelectedAssignmentId(assignmentId);
    setOpenModal(true);
  };

  const handleConfirmChangeStatus = async () => {
    try {
      const response = await axios.get(apiUrlUpdateAssignmentStatus, {
        params: {
          estado: 'ENCURSO',
          paciente_id: selectedAssignmentId.paciente_id,
          fecha: selectedAssignmentId.fecha
        }
      });

      const updatedPatients = assignedPatients.map(patient => {
        if (patient.paciente_id === selectedAssignmentId.paciente_id) {
          return {
            ...patient,
            estado_atencion: 'ENCURSO'
          };
        }
        return patient;
      });
      setAssignedPatients(updatedPatients);
      setFilteredPatients(updatedPatients);

      setOpenModal(false);
    } catch (error) {
      console.error('Error al actualizar estado de asignación:', error);
    }
  };

  const handleConfirmSuspendAssignment = async () => {
    try {
      const response = await axios.get(apiUrlUpdateAssignmentStatus, {
        params: {
          estado: 'SUSPENDIDA',
          paciente_id: selectedSuspendAssignmentId.paciente_id,
          fecha: selectedSuspendAssignmentId.fecha
        }
      });

      const updatedPatients = assignedPatients.map(patient => {
        if (patient.paciente_id === selectedSuspendAssignmentId.paciente_id) {
          return {
            ...patient,
            estado_atencion: 'SUSPENDIDO'
          };
        }
        return patient;
      });
      setAssignedPatients(updatedPatients);
      setFilteredPatients(updatedPatients);

      setOpenSuspendModal(false);
    } catch (error) {
      console.error('Error al suspender asignación:', error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseSuspendModal = () => {
    setOpenSuspendModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const renderTable = (assignments, title) => (
    <Box sx={{ marginBottom: 4 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {title}
      </Typography>
      <TableContainer>
        <Table size="small" sx={{ minWidth: 650 }}>
          <TableHead sx={{ backgroundColor: '#002A2E' }}>
            <TableRow>
              <TableCell sx={{ padding: '4px' }}>
                <Typography color="white" fontWeight="bold" fontSize="small">Paciente ID</Typography>
              </TableCell>
              <TableCell sx={{ padding: '4px' }}>
                <Typography color="white" fontWeight="bold" fontSize="small">Nombre del Paciente</Typography>
              </TableCell>
              <TableCell sx={{ padding: '4px' }}>
                <Typography color="white" fontWeight="bold" fontSize="small">Equipo Médico</Typography>
              </TableCell>
              <TableCell sx={{ padding: '4px' }}>
                <Typography color="white" fontWeight="bold" fontSize="small">Fecha de Asignación</Typography>
              </TableCell>
              <TableCell sx={{ padding: '4px' }}>
                <Typography color="white" fontWeight="bold" fontSize="small">Estado</Typography>
              </TableCell>
              <TableCell sx={{ padding: '4px' }}>
                <Typography color="white" fontWeight="bold" fontSize="small">Revisiones</Typography>
              </TableCell>
              <TableCell sx={{ padding: '4px' }}>
                <Typography color="white" fontWeight="bold" fontSize="small">Acciones</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignments.map((assignment, index) => {
              const assignmentDate = new Date(assignment.fecha);
              const formattedAssignmentDate = !isNaN(assignmentDate) ? assignmentDate.toISOString().split('T')[0] : null;

              const renderProfessionChip = (profesion, estado, field) => {
                let color;
                let onClickAction = null;

                if (assignment.estado_atencion === 'AGENDADO') {
                  color = 'default'; // Color blanco (predeterminado)
                } else {
                  switch (estado) {
                    case 'COMPLETA':
                      color = 'success';
                      break;
                    case 'BORRADOR':
                      color = 'warning';
                      break;
                    default:
                      color = 'default';
                  }

                  onClickAction = () => handleIconClick(assignment, field);
                }

                return (
                  <Chip
                    label={profesion}
                    size="small"
                    color={color}
                    onClick={onClickAction}
                    sx={{
                      cursor: assignment.estado_atencion === 'AGENDADO' ? 'default' : 'pointer',
                      fontSize: '8px',  // Reducción adicional del tamaño del texto
                      minWidth: '70px',  // Reducir el ancho mínimo para uniformidad
                      height: '20px',    // Reducir la altura para un tamaño más compacto
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: assignment.estado_atencion === 'AGENDADO' ? '#E0E0E0' : undefined, // Color blanco si está agendado
                      color: assignment.estado_atencion === 'AGENDADO' ? '#000' : undefined, // Texto negro si está agendado
                    }}
                  />
                );
              };

              return (
                <TableRow key={index} sx={{ boxShadow: 3, '&:hover': { backgroundColor: '#77B0AA' }, padding: '4px' }}>
                  <TableCell sx={{ padding: '4px', fontSize: 'small' }}>{assignment.paciente_id}</TableCell>
                  <TableCell sx={{ padding: '4px', fontSize: 'small' }}>{assignment.nombre}</TableCell>
                  <TableCell sx={{ padding: '4px', fontSize: 'small' }}>{`Equipo ${assignment.equipo_medico}`}</TableCell>
                  <TableCell sx={{ padding: '4px', fontSize: 'small' }}>{formattedAssignmentDate}</TableCell>
                  <TableCell sx={{ padding: '4px', fontSize: 'small' }}>{assignment.estado_atencion}</TableCell>
                  <TableCell sx={{ padding: '4px', fontSize: 'small', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                    <Tooltip title="Médico">
                      {renderProfessionChip('Médico', assignment.ficha?.medico?.estado, 'Médico')}
                    </Tooltip>
                    <Tooltip title="Enfermería">
                      {renderProfessionChip('Enfermería', assignment.ficha?.enfermeria?.estado, 'Enfermería')}
                    </Tooltip>
                    <Tooltip title="Kinesiología">
                      {renderProfessionChip('Kinesiología', assignment.ficha?.kinesiologia?.estado, 'Kinesiología')}
                    </Tooltip>
                    <Tooltip title="Fonoaudiología">
                      {renderProfessionChip('Fonoaudiología', assignment.ficha?.fonoaudiologia?.estado, 'Fonoaudiología')}
                    </Tooltip>
                    <Tooltip title="Terapia Ocupacional">
                      {renderProfessionChip('Terapia Ocupacional', assignment.ficha?.terapiaOcupacional?.estado, 'Terapia Ocupacional')}
                    </Tooltip>
                    <Tooltip title="TENS">
                      {renderProfessionChip('TENS', assignment.ficha?.tens?.estado, 'TENS')}
                    </Tooltip>
                    <Tooltip title="Trabajador Social">
                      {renderProfessionChip('Trabajador Social', assignment.ficha?.trabajoSocial?.estado, 'Trabajador Social')}
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ padding: '4px', fontSize: 'small' }}>
                    <IconButton onClick={() => resumeAssignment(assignment)} sx={{ color: '#008000', padding: '4px' }}>
                      <NotesIcon fontSize="small" />
                    </IconButton>
                    {/* {assignment.estado_atencion === 'ENCURSO' ? (
                      <IconButton onClick={() => viewAssignment(assignment)} sx={{ color: '#008000', padding: '4px' }}>
                        <NotesIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ color: '#E3FEF7', padding: '4px' }}>
                        <NotesIcon fontSize="small" />
                      </IconButton>
                    )} */}
                    {assignment.estado_atencion === 'AGENDADO' ? (
                      <IconButton onClick={() => suspendAssignment(assignment)} sx={{ color: '#008000', padding: '4px' }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ color: '#E3FEF7', padding: '4px' }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                    {assignment.estado_atencion === 'AGENDADO' ? (
                      <IconButton onClick={() => changeAssignmentStatus(assignment)} sx={{ color: '#008000', padding: '4px' }}>
                        <PlayCircleIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ color: '#E3FEF7', padding: '4px' }}>
                        <PlayCircleIcon fontSize="small" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const enCursoAssignments = filteredPatients.filter(patient => patient.estado_atencion === 'ENCURSO');
  const agendadoAssignments = filteredPatients.filter(patient => patient.estado_atencion === 'AGENDADO');
  const otrosAssignments = filteredPatients.filter(patient => !['ENCURSO', 'AGENDADO'].includes(patient.estado_atencion));

  const completedAssignments = filteredPatients.filter(patient => patient.estado_atencion === 'COMPLETADA');
  const otherAssignments = filteredPatients.filter(patient => !['ENCURSO', 'AGENDADO', 'COMPLETADA'].includes(patient.estado_atencion));

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ boxShadow: 6, padding: 2 }}>

        <Box 
          sx={{ backgroundColor: '#003C43', flexGrow: 1, display: 'flex', padding: 1, borderRadius: 1, marginBottom: 2, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h6" color="white" sx={{ marginLeft: 6 }}>
            Asignaciones y Atenciones
          </Typography>
          {(user_rol === 'Admin' && isAuthenticated) && (
          <Link to="/PatientAssignment" align="start">
            <Button variant="contained" sx={{ backgroundColor: '#003C43', color: 'white', '&:hover': { backgroundColor: '#134566' } }}>
              Agregar Asignación         
            </Button>
          </Link>
          )}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
          <Box sx={{ flexGrow: 1, boxShadow: 3, padding: 2, marginRight: 2, borderRadius: 1, textAlign: 'center', backgroundColor: '#f5f5f5' }}>
            <Typography variant="h6">Asignaciones En Curso</Typography>
            <Typography variant="h4">{enCursoAssignments.length}</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, boxShadow: 3, padding: 2, marginRight: 2, borderRadius: 1, textAlign: 'center', backgroundColor: '#f5f5f5' }}>
            <Typography variant="h6">Asignaciones Completadas</Typography>
            <Typography variant="h4">{completedAssignments.length}</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, boxShadow: 3, padding: 2, marginRight: 2, borderRadius: 1, textAlign: 'center', backgroundColor: '#f5f5f5' }}>
            <Typography variant="h6">Asignaciones Agendadas</Typography>
            <Typography variant="h4">{agendadoAssignments.length}</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, boxShadow: 3, padding: 2, borderRadius: 1, textAlign: 'center', backgroundColor: '#f5f5f5' }}>
            <Typography variant="h6">Historial</Typography>
            <Typography variant="h4">{otherAssignments.length}</Typography>
          </Box>
        </Box>



        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
          <TextField
            label="Nombre del Paciente"
            variant="outlined"
            size="small"
            name="nombre"
            value={filters.nombre}
            onChange={handleFilterChange}
            sx={{ flexGrow: 1, borderRadius: 1 }}
          />
          <TextField
            label="Equipo Médico"
            variant="outlined"
            size="small"
            name="equipo"
            value={filters.equipo}
            onChange={handleFilterChange}
            sx={{ flexGrow: 1, borderRadius: 1 }}
          />
          <TextField
            label="Estado"
            variant="outlined"
            size="small"
            name="estado_atencion"
            value={filters.estado_atencion}
            onChange={handleFilterChange}
            sx={{ flexGrow: 1, borderRadius: 1 }}
          />
          <TextField
            label="Fecha de Asignación"
            variant="outlined"
            size="small"
            type="date"
            name="fecha"
            value={filters.fecha}
            onChange={handleFilterChange}
            sx={{ flexGrow: 1, borderRadius: 1 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        {renderTable(enCursoAssignments, 'Asignaciones En Curso')}
        {renderTable(agendadoAssignments, 'Asignaciones Agendadas')}
        {renderTable(otrosAssignments, 'Historial')}

        <TablePagination
          labelRowsPerPage="Filas por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredPatients.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-selectLabel': { color: '#002A2E' },
            '& .MuiTablePagination-input': { color: '#002A2E' },
            '& .MuiTablePagination-selectIcon': { color: '#002A2E' },
            '& .MuiTablePagination-actions': { color: '#002A2E' },
          }}
        />

        <Dialog open={isResumeModalOpen} onClose={() => setIsResumeModalOpen(false)} maxWidth="md" fullWidth>
          <DialogContent>
            {resumeData ? (
              <div className="document-content">
                <hr/>
                <Box component="h3" sx={{ textAlign: 'center' }}>Datos del Paciente</Box>
                <hr/>
                <p><strong>Nombre:</strong> {resumeData.nombre}</p>
                <p><strong>RUT:</strong> {resumeData.rut}</p>
                <p><strong>Dirección:</strong> {resumeData.direccion}</p>
                <hr/>
                <Box component="h3" sx={{ textAlign: 'center' }}>Estado de la Asignación</Box>
                <hr/>
                <p><strong>Estado Asignación:</strong> {resumeData.estado_asignacion}</p>
                <p><strong>Estado Atención:</strong> {resumeData.estado_atencion}</p>
                <p><strong>Fecha:</strong> {resumeData.fecha}</p>
                <hr/>
                <Box component="h3" sx={{ textAlign: 'center' }}>Enfermería</Box>
                <hr/>
                <p><strong>Control Signos Vitales:</strong> {resumeData.enfermeria?.controlSignosVitales}</p>
                <p><strong>Evolución:</strong> {resumeData.enfermeria?.evolucion}</p>
                <p><strong>Indicaciones:</strong> {resumeData.enfermeria?.indicaciones}</p>
                <p><strong>Observaciones:</strong> {resumeData.enfermeria?.observaciones}</p>
                <p><strong>Plan:</strong> {resumeData.enfermeria?.plan}</p>
                <hr/>
                <Box component="h3" sx={{ textAlign: 'center' }}>Médico</Box>
                <hr/>
                <p><strong>Control Signos Vitales:</strong> {resumeData.medico?.controlSignosVitales}</p>
                <p><strong>Diagnóstico:</strong> {resumeData.medico?.diagnostico}</p>
                <p><strong>Evolución:</strong> {resumeData.medico?.evolucion}</p>
                <p><strong>Historia Clínica:</strong> {resumeData.medico?.historiaClinica}</p>
                <p><strong>Indicaciones:</strong> {resumeData.medico?.indicaciones}</p>
                <p><strong>Observaciones:</strong> {resumeData.medico?.observaciones}</p>
                <hr/>
                <Box component="h3" sx={{ textAlign: 'center' }}>Fonoaudiólogo</Box>
                <hr/>
                <p><strong>Control Signos Vitales:</strong> {resumeData.fonoaudiologo?.controlSignosVitales}</p>
                <p><strong>Diagnóstico:</strong> {resumeData.fonoaudiologo?.diagnostico}</p>
                <p><strong>Evolución:</strong> {resumeData.fonoaudiologo?.evolucion}</p>
                <p><strong>Indicaciones:</strong> {resumeData.fonoaudiologo?.indicaciones}</p>
                <hr/>
                <Box component="h3" sx={{ textAlign: 'center' }}>Kinesiólogo</Box>
                <hr/>
                <p><strong>Control Signos Vitales:</strong> {resumeData.kinesiologo?.controlSignosVitales}</p>
                <p><strong>Diagnóstico:</strong> {resumeData.kinesiologo?.diagnostico}</p>
                <p><strong>Evolución:</strong> {resumeData.kinesiologo?.evolucion}</p>
                <p><strong>Indicaciones:</strong> {resumeData.kinesiologo?.indicaciones}</p>
                <hr/>
                <Box component="h3" sx={{ textAlign: 'center' }}>TENS</Box>
                <hr/>
                <p><strong>Control Signos Vitales:</strong> {resumeData.tens?.controlSignosVitales}</p>
                <p><strong>Evolución:</strong> {resumeData.tens?.evolucion}</p>
                <p><strong>Observaciones:</strong> {resumeData.tens?.observaciones}</p>
                <hr/>
                <Box component="h3" sx={{ textAlign: 'center' }}>Terapia Ocupacional</Box>
                <hr/>
                <p><strong>Control Signos Vitales:</strong> {resumeData.terapiaOcupacional?.controlSignosVitales}</p>
                <p><strong>Diagnóstico:</strong> {resumeData.terapiaOcupacional?.diagnostico}</p>
                <p><strong>Evolución:</strong> {resumeData.terapiaOcupacional?.evolucion}</p>
                <p><strong>Indicaciones:</strong> {resumeData.terapiaOcupacional?.indicaciones}</p>
                <p><strong>Observaciones:</strong> {resumeData.terapiaOcupacional?.observaciones}</p>
                <hr/>
                <Box component="h3" sx={{ textAlign: 'center' }}>Trabajador Social</Box>
                <hr/>
                <p><strong>Evolución:</strong> {resumeData.trabajadorSocial?.evolucion}</p>
                <p><strong>Observaciones:</strong> {resumeData.trabajadorSocial?.observaciones}</p>
                <hr/>

                {(resumeData.estado_atencion === 'ENCURSO' && user_rol === 'Admin') &&(
                  <>
                    <Box component="h3" sx={{ textAlign: 'center' }}>Cierre de Ficha</Box>
                    <TextField
                      label="Observación Final"
                      fullWidth
                      multiline
                      rows={4}
                      margin="normal"
                      value={resumeData.observacionFinal || ''}
                      onChange={(e) => setResumeData({ ...resumeData, observacionFinal: e.target.value })}
                    />
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Estado Paciente</InputLabel>
                    <Select
                      value={resumeData.estadoFinal || ''}
                      onChange={(e) => setResumeData({ ...resumeData, estadoFinal: e.target.value })}
                    >
                      <MenuItem value="completada">De alta</MenuItem>
                      <MenuItem value="Retiro">Retirado</MenuItem>
                      <MenuItem value="finalizada">Continua con tratamiento</MenuItem>
                      <MenuItem value="pendiente">Tiene visita incompleta</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Mostrar el Select de motivo de retiro solo si el estado es "Retirado" */}
                  {resumeData.estadoFinal === 'Retiro' && (
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Motivo de Retiro</InputLabel>
                      <Select
                        value={resumeData.motivoRetiro || ''}
                        onChange={(e) => setResumeData({ ...resumeData, motivoRetiro: e.target.value })}
                      >
                        <MenuItem value="Paciente fallecido">Paciente fallecido</MenuItem>
                        <MenuItem value="Paciente cambió de domicilio">Paciente cambió de domicilio</MenuItem>
                        <MenuItem value="Renuncia a tratamiento">Paciente renuncia a tratamiento</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  </>
                )}

                {resumeData.estado_atencion === 'COMPLETADA' && (
                  <>
                    <Box component="h3" sx={{ textAlign: 'center' }}>Cierre de Ficha</Box>
                    <p><strong>Fecha de Cierre:</strong> {new Date(resumeData.fechaCierre).toLocaleString('es-ES', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit'
                    })}</p>
                    <p><strong>Observación Final:</strong> {resumeData.observacionCierre}</p>
                  </>
                )}
              </div>
            ) : (
              <Typography variant="body1" color="textSecondary">
                No se han encontrado datos para mostrar.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={exportToPDF} color="primary">Exportar a PDF</Button>
            <Button onClick={() => setIsResumeModalOpen(false)} color="primary">Cerrar</Button>
            {(resumeData?.estado_atencion === 'ENCURSO' && user_rol === 'Admin')&&(
              <Button onClick={handleCloseFicha} color="primary" variant="contained">Confirmar</Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle>
            {isEditing ? `Editar Información` 
                       : `Ver Información`}
          </DialogTitle>
          <DialogContent>
            {selectedField === 'Enfermería' && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Enfermería - {selectedAssignment.ficha?.enfermeria?.nombre_profesional}
                </Typography>
                <TextField
                  label="Control Signos Vitales"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.enfermeria?.registros?.controlSignosVitales || ''}
                  onChange={(e) => handleFieldChange('enfermeria', 'controlSignosVitales', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.enfermeria?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Evolución"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.enfermeria?.registros?.evolucion || ''}
                  onChange={(e) => handleFieldChange('enfermeria', 'evolucion', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.enfermeria?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Indicaciones"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.enfermeria?.registros?.indicaciones || ''}
                  onChange={(e) => handleFieldChange('enfermeria', 'indicaciones', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.enfermeria?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Observaciones"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.enfermeria?.registros?.observaciones || ''}
                  onChange={(e) => handleFieldChange('enfermeria', 'observaciones', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.enfermeria?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Plan"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.enfermeria?.registros?.plan || ''}
                  onChange={(e) => handleFieldChange('enfermeria', 'plan', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.enfermeria?.estado === 'SUSPENDIDA'}
                />
              </Box>
            )}

            {selectedField === 'Médico' && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Médico - {selectedAssignment.ficha?.medico?.nombre_profesional}
                </Typography>
                <TextField
                  label="Control Signos Vitales"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.medico?.registros?.controlSignosVitales || ''}
                  onChange={(e) => handleFieldChange('medico', 'controlSignosVitales', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.medico?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Diagnóstico"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.medico?.registros?.diagnostico || ''}
                  onChange={(e) => handleFieldChange('medico', 'diagnostico', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.medico?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Evolución"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.medico?.registros?.evolucion || ''}
                  onChange={(e) => handleFieldChange('medico', 'evolucion', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.medico?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Historia Clínica"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.medico?.registros?.historiaClinica || ''}
                  onChange={(e) => handleFieldChange('medico', 'historiaClinica', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.medico?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Indicaciones"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.medico?.registros?.indicaciones || ''}
                  onChange={(e) => handleFieldChange('medico', 'indicaciones', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.medico?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Observaciones"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.medico?.registros?.observaciones || ''}
                  onChange={(e) => handleFieldChange('medico', 'observaciones', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.medico?.estado === 'SUSPENDIDA'}
                />
              </Box>
            )}

            {selectedField === 'Fonoaudiología' && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Fonoaudiología - {selectedAssignment.ficha?.fonoaudiologia?.nombre_profesional}
                </Typography>
                <TextField
                  label="Control Signos Vitales"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.fonoaudiologia?.registros?.controlSignosVitales || ''}
                  onChange={(e) => handleFieldChange('fonoaudiologo', 'controlSignosVitales', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.fonoaudiologia?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Diagnóstico"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.fonoaudiologia?.registros?.diagnostico || ''}
                  onChange={(e) => handleFieldChange('fonoaudiologo', 'diagnostico', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.fonoaudiologia?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Evolución"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.fonoaudiologia?.registros?.evolucion || ''}
                  onChange={(e) => handleFieldChange('fonoaudiologo', 'evolucion', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.fonoaudiologia?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Indicaciones"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.fonoaudiologia?.registros?.indicaciones || ''}
                  onChange={(e) => handleFieldChange('fonoaudiologo', 'indicaciones', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.fonoaudiologia?.estado === 'SUSPENDIDA'}
                />
              </Box>
            )}

            {selectedField === 'Kinesiología' && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Kinesiología - {selectedAssignment.ficha?.kinesiologia?.nombre_profesional}
                </Typography>
                <TextField
                  label="Control Signos Vitales"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.kinesiologia?.registros?.controlSignosVitales || ''}
                  onChange={(e) => handleFieldChange('kinesiologo', 'controlSignosVitales', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.kinesiologia?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Diagnóstico"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.kinesiologia?.registros?.diagnostico || ''}
                  onChange={(e) => handleFieldChange('kinesiologo', 'diagnostico', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.kinesiologia?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Evolución"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.kinesiologia?.registros?.evolucion || ''}
                  onChange={(e) => handleFieldChange('kinesiologo', 'evolucion', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.kinesiologia?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Indicaciones"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.kinesiologia?.registros?.indicaciones || ''}
                  onChange={(e) => handleFieldChange('kinesiologo', 'indicaciones', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.kinesiologia?.estado === 'SUSPENDIDA'}
                />
              </Box>
            )}

            {selectedField === 'TENS' && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  TENS - {selectedAssignment.ficha?.tens?.nombre_profesional}
                </Typography>
                <TextField
                  label="Control Signos Vitales"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.tens?.registros?.controlSignosVitales || ''}
                  onChange={(e) => handleFieldChange('tens', 'controlSignosVitales', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.tens?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Evolución"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.tens?.registros?.evolucion || ''}
                  onChange={(e) => handleFieldChange('tens', 'evolucion', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.tens?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Observaciones"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.tens?.registros?.observaciones || ''}
                  onChange={(e) => handleFieldChange('tens', 'observaciones', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.tens?.estado === 'SUSPENDIDA'}
                />
              </Box>
            )}

            {selectedField === 'Terapia Ocupacional' && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Terapia Ocupacional - {selectedAssignment.ficha?.terapiaOcupacional?.nombre_profesional}
                </Typography>
                <TextField
                  label="Control Signos Vitales"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.terapiaOcupacional?.registros?.controlSignosVitales || ''}
                  onChange={(e) => handleFieldChange('terapiaOcupacional', 'controlSignosVitales', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.terapiaOcupacional?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Diagnóstico"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.terapiaOcupacional?.registros?.diagnostico || ''}
                  onChange={(e) => handleFieldChange('terapiaOcupacional', 'diagnostico', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.terapiaOcupacional?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Evolución"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.terapiaOcupacional?.registros?.evolucion || ''}
                  onChange={(e) => handleFieldChange('terapiaOcupacional', 'evolucion', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.terapiaOcupacional?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Indicaciones"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.terapiaOcupacional?.registros?.indicaciones || ''}
                  onChange={(e) => handleFieldChange('terapiaOcupacional', 'indicaciones', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.terapiaOcupacional?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Observaciones"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.terapiaOcupacional?.registros?.observaciones || ''}
                  onChange={(e) => handleFieldChange('terapiaOcupacional', 'observaciones', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.terapiaOcupacional?.estado === 'SUSPENDIDA'}
                />
              </Box>
            )}

            {selectedField === 'Trabajador Social' && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Trabajador Social - {selectedAssignment.ficha?.trabajoSocial?.nombre_profesional}
                </Typography>
                <TextField
                  label="Evolución"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.trabajoSocial?.registros?.evolucion || ''}
                  onChange={(e) => handleFieldChange('trabajoSocial', 'evolucion', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.trabajoSocial?.estado === 'SUSPENDIDA'}
                />
                <TextField
                  label="Observaciones"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={selectedAssignment.ficha?.trabajoSocial?.registros?.observaciones || ''}
                  onChange={(e) => handleFieldChange('trabajoSocial', 'observaciones', e.target.value)}
                  disabled={!isEditing || selectedAssignment.ficha?.trabajoSocial?.estado === 'SUSPENDIDA'}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
          {isEditing && (
            <>
              {isEditing && selectedAssignment.ficha?.[selectedField]?.estado !== 'COMPLETA' && (
                <Button onClick={() => handleSave('BORRADOR', selectedField)} color="primary">
                  Guardar Borrador
                </Button>
              )}
              <Button onClick={() => handleSave('COMPLETA', selectedField)} color="primary">
                Guardar y Cerrar
              </Button>
            </>
          )}
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
        </Dialog>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Box
              sx={{
                backgroundColor: 'white',
                boxShadow: 24,
                padding: 4,
                borderRadius: 2,
                maxWidth: 400,
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <Typography variant="h6" gutterBottom>
                Confirmación
              </Typography>
              <Typography variant="body1" gutterBottom>
                ¿Está seguro de cambiar el estado de la asignación a "ENCURSO"?
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Button variant="contained" color="primary" onClick={handleConfirmChangeStatus} sx={{ marginRight: 2 }}>
                  Confirmar
                </Button>
                <Button variant="outlined" color="primary" onClick={handleCloseModal}>
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>

        <Modal
          open={openSuspendModal}
          onClose={handleCloseSuspendModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openSuspendModal}>
            <Box
              sx={{
                backgroundColor: 'white',
                boxShadow: 24,
                padding: 4,
                borderRadius: 2,
                maxWidth: 400,
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <Typography variant="h6" gutterBottom>
                Confirmación de Suspensión
              </Typography>
              <Typography variant="body1" gutterBottom>
                ¿Está seguro de suspender esta asignación?
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Button variant="contained" color="primary" onClick={handleConfirmSuspendAssignment} sx={{ marginRight: 2 }}>
                  Confirmar
                </Button>
                <Button variant="outlined" color="primary" onClick={handleCloseSuspendModal}>
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>

      </Paper>
    </Box>
  );
};

export default AssignmentHistory;
