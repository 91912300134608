import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, IconButton, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, TablePagination, Box, FormControl,
  InputLabel, Select, MenuItem, Typography
} from '@mui/material';
import axios from 'axios';
import { Delete, Edit } from '@mui/icons-material';
import { Link } from "react-router-dom";
import FilterListIcon from '@mui/icons-material/FilterList';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import jsPDF from 'jspdf';
import { useAuth0 } from "@auth0/auth0-react";

const initialRows = [];

const filterContent = {
  rut: "Rut",
  nombre: "Nombre",
  comuna: "Comuna"
};

const CRUDTable = () => {
  const [rows, setRows] = useState(initialRows);
  const [filteredRows, setFilteredRows] = useState(initialRows);
  const [open, setOpen] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [formData, setFormData] = useState({ direccion: '', comuna: '', telefono: '' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterType, setFilterType] = useState('rut');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [data, setData] = useState([]);
  const [openPatientInfoDialog, setOpenPatientInfoDialog] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [openFullInfoDialog, setOpenFullInfoDialog] = useState(false);
  const [selectedFullPatient, setSelectedFullPatient] = useState(null);

  const handleOpenPatientInfoDialog = async (paciente_id, fecha) => {
    const response = await axios.get(`https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/ver-ficha-paciente?paciente_id=${paciente_id}&fecha=${fecha}`);
    const data = response.data;
    setSelectedPatient(data);
    setOpenPatientInfoDialog(true);
  };

  const {user, isAuthenticated, loginWithRedirect, logout, isLoading,} = useAuth0();
  let profesion = '';
  let rol = '';
  const defaultPicture = '/static/images/default-avatar.png';

  if (isAuthenticated) {
    const roles = user['http://localhost:3000/roles'] || [];
    const metadata = user['http://localhost:3000/metadata'] || [];
    profesion = metadata;
    rol = roles[0];
  }

  const handleClosePatientInfoDialog = () => {
    setOpenPatientInfoDialog(false);
  };

  const handleOpenInfoDialog = async (paciente_id) => {
    try {
      const response = await axios.get(`https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-asignacion-pacientes?paciente_id=${paciente_id}`);
      setData(response.data);
      setOpenInfoDialog(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  const handleOpen = (row = null) => {
    setEditingRow(row);
    setFormData(row ? { direccion: row.direccion, comuna: row.comuna, telefono: row.telefono } : { direccion: '', comuna: '', telefono: '' });
    setOpen(true);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    let yPos = 10;
    const lineHeight = 10;
    const pageHeight = doc.internal.pageSize.height;

    const addSection = (title, content) => {
      if (!title || !Array.isArray(content)) return;

      doc.setFontSize(14);
      doc.setFont('times', 'bold');
      doc.text(title, 10, yPos);
      yPos += lineHeight;

      doc.setFontSize(12);
      doc.setFont('times', 'normal');
      content.forEach((text) => {
        if (!text) return;

        const splitText = doc.splitTextToSize(text, 180);
        splitText.forEach((line) => {
          if (yPos > pageHeight - lineHeight) {
            doc.addPage();
            yPos = 10;
          }
          doc.text(line, 10, yPos);
          yPos += lineHeight;
        });
      });
      yPos += lineHeight;
    };

    addSection('Datos del Paciente', [
      `Nombre: ${selectedPatient?.nombre || ''}`,
      `RUT: ${selectedPatient?.rut || ''}`,
      `Dirección: ${selectedPatient?.direccion || ''}`
    ]);

    addSection('Estado de la Asignación', [
      `Estado Asignación: ${selectedPatient?.estado_asignacion || ''}`,
      `Estado Atención: ${selectedPatient?.estado_atencion || ''}`,
      `Fecha: ${selectedPatient?.fecha || ''}`
    ]);

    addSection('Enfermería', [
      `Control Signos Vitales: ${selectedPatient?.enfermeria?.controlSignosVitales || ''}`,
      `Evolución: ${selectedPatient?.enfermeria?.evolucion || ''}`,
      `Indicaciones: ${selectedPatient?.enfermeria?.indicaciones || ''}`,
      `Observaciones: ${selectedPatient?.enfermeria?.observaciones || ''}`,
      `Plan: ${selectedPatient?.enfermeria?.plan || ''}`
    ]);

    addSection('Médico', [
      `Control Signos Vitales: ${selectedPatient?.medico?.controlSignosVitales || ''}`,
      `Diagnóstico: ${selectedPatient?.medico?.diagnostico || ''}`,
      `Evolución: ${selectedPatient?.medico?.evolucion || ''}`,
      `Historia Clínica: ${selectedPatient?.medico?.historiaClinica || ''}`,
      `Indicaciones: ${selectedPatient?.medico?.indicaciones || ''}`,
      `Observaciones: ${selectedPatient?.medico?.observaciones || ''}`
    ]);

    addSection('Fonoaudiólogo', [
      `Control Signos Vitales: ${selectedPatient?.fonoaudiologo?.controlSignosVitales || ''}`,
      `Diagnóstico: ${selectedPatient?.fonoaudiologo?.diagnostico || ''}`,
      `Evolución: ${selectedPatient?.fonoaudiologo?.evolucion || ''}`,
      `Indicaciones: ${selectedPatient?.fonoaudiologo?.indicaciones || ''}`
    ]);

    addSection('Kinesiólogo', [
      `Control Signos Vitales: ${selectedPatient?.kinesiologo?.controlSignosVitales || ''}`,
      `Diagnóstico: ${selectedPatient?.kinesiologo?.diagnostico || ''}`,
      `Evolución: ${selectedPatient?.kinesiologo?.evolucion || ''}`,
      `Indicaciones: ${selectedPatient?.kinesiologo?.indicaciones || ''}`
    ]);

    addSection('TENS', [
      `Control Signos Vitales: ${selectedPatient?.tens?.controlSignosVitales || ''}`,
      `Evolución: ${selectedPatient?.tens?.evolucion || ''}`,
      `Observaciones: ${selectedPatient?.tens?.observaciones || ''}`
    ]);

    addSection('Terapia Ocupacional', [
      `Control Signos Vitales: ${selectedPatient?.terapiaOcupacional?.controlSignosVitales || ''}`,
      `Diagnóstico: ${selectedPatient?.terapiaOcupacional?.diagnostico || ''}`,
      `Evolución: ${selectedPatient?.terapiaOcupacional?.evolucion || ''}`,
      `Indicaciones: ${selectedPatient?.terapiaOcupacional?.indicaciones || ''}`,
      `Observaciones: ${selectedPatient?.terapiaOcupacional?.observaciones || ''}`
    ]);

    addSection('Trabajador Social', [
      `Evolución: ${selectedPatient?.trabajadorSocial?.evolucion || ''}`,
      `Observaciones: ${selectedPatient?.trabajadorSocial?.observaciones || ''}`
    ]);

    doc.save(`Ficha_Paciente_${selectedPatient.rut}_${selectedPatient.fecha}.pdf`);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-pacientes');
      setRows(response.data || []);
      setFilteredRows(response.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
  };

  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      if (editingRow) {
        await axios.post('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/actualizar-direccion-paciente', {
          paciente_id: editingRow.paciente_id,
          direccion: formData.direccion,
          comuna: formData.comuna,
          telefono: formData.telefono
        });
        setRows(rows.map((row) => (row.paciente_id === editingRow.paciente_id ? { ...row, ...formData } : row)));
      }
      await fetchData(); 
      handleClose();
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleDelete = async () => {
    if (!rowToDelete) return;
    try {
      await axios.delete(`https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/eliminar-paciente?paciente_id=${rowToDelete.paciente_id}`);
      setRows(rows.filter((row) => row.paciente_id !== rowToDelete.paciente_id));
      setFilteredRows(filteredRows.filter((row) => row.paciente_id !== rowToDelete.paciente_id));
    } catch (error) {
      console.error('Error deleting data:', error);
    }
    setOpenDeleteDialog(false);
  };

  const handleFilter = (field, value) => {
    const filtered = rows.filter(row => row[field].toLowerCase().includes(value.toLowerCase()));
    setFilteredRows(filtered);
  };

  const handleOpenDeleteDialog = (row) => {
    setRowToDelete(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setRowToDelete(null);
  };

  const handleOpenFullInfoDialog = (patientData) => {
    setSelectedFullPatient(patientData);
    setOpenFullInfoDialog(true);
  };

  const handleCloseFullInfoDialog = () => {
    setOpenFullInfoDialog(false);
    setSelectedFullPatient(null);
  };

  return (
    <Box sx={{ width: '100%', boxShadow: 6, padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {(rol === 'Admin' && isAuthenticated) && (
        <Link to="/PatientForm">
          <Button variant="contained" color="primary">Añadir paciente</Button>
        </Link>
      )}
        <Box>
          <FormControl sx={{ minWidth: 120, marginTop: 3 }}>
            <InputLabel sx={{ marginTop: -1 }}></InputLabel>
            <Select
              value={filterType}
              onChange={(e) => handleFilterChange(e.target.value)}
            >
              {Object.keys(filterContent).map((key) => (
                <MenuItem key={key} value={key}>{filterContent[key]}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField sx={{ minWidth: 120, marginTop: 3 }} label={`Ingresa ${filterContent[filterType]}`} onChange={(e) => handleFilter(filterType, e.target.value)} />
        </Box>
      </Box>
      <TableContainer component={Paper} style={{ marginTop: 20, marginBottom: 40 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID Paciente</TableCell>
              <TableCell>Rut</TableCell>
              <TableCell>Nombre</TableCell>
              {/* <TableCell>Complejidad</TableCell> */}
              <TableCell>Estado Actual</TableCell>
              <TableCell>Dirección</TableCell>
              {/* <TableCell>Comuna</TableCell> */}
            </TableRow>
          </TableHead>
<TableBody>
  {(rowsPerPage > 0
    ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredRows
  ).map((row) => (
    <TableRow key={row.paciente_id}>
      <TableCell>{row.paciente_id}</TableCell>
      <TableCell>{row.rut}</TableCell>
      <TableCell>{row.nombre}</TableCell>
      {/* <TableCell>{row.complejidad}</TableCell> */}
      <TableCell>{row.estado_asignacion}</TableCell>
      <TableCell>{row.direccion}</TableCell>
      {/* <TableCell>{row.comuna}</TableCell> */}
      <TableCell>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleOpenFullInfoDialog(row)}
            startIcon={<VisibilityIcon />}
          >
            Ver
          </Button>
          {(rol === 'Admin' && isAuthenticated) && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleOpen(row)}
            startIcon={<Edit />}
          >
            Editar
          </Button>)}
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleOpenInfoDialog(row.paciente_id)}
            startIcon={<StickyNote2Icon />}
          >
            Historial
          </Button>
          {(rol === 'Admin' && isAuthenticated) && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleOpenDeleteDialog(row)}
            startIcon={<Delete />}
          >
            Eliminar
          </Button>)}
        </Box>
      </TableCell>
    </TableRow>
  ))}
</TableBody>


        </Table>
        <TablePagination
          labelRowsPerPage="Filas por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingRow ? 'Editar Información del paciente' : 'Agregar Nueva Fila'}</DialogTitle>
        <DialogContent>
          <TextField margin="dense" name="direccion" label="Dirección" type="text" fullWidth value={formData.direccion} onChange={handleChange} />
          <TextField margin="dense" name="comuna" label="Comuna" type="text" fullWidth value={formData.comuna} onChange={handleChange} />
          <TextField margin="dense" name="telefono" label="Teléfono" type="text" fullWidth value={formData.telefono} onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancelar</Button>
          <Button onClick={handleSubmit} color="primary">{editingRow ? 'Actualizar' : 'Agregar'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <p>¿Está seguro de que desea eliminar este paciente?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancelar</Button>
          <Button onClick={handleDelete} color="secondary">Eliminar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openInfoDialog} onClose={handleCloseInfoDialog} maxWidth="md" fullWidth>
        <DialogTitle>Historial de asignaciones del paciente</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID Paciente</TableCell>
                  <TableCell>Rut</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Dirección</TableCell>
                  <TableCell>Comuna</TableCell>
                  <TableCell>Estado Atención</TableCell>
                  <TableCell>Equipo Médico</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.paciente_id}>
                    <TableCell>{row.paciente_id}</TableCell>
                    <TableCell>{row.rut}</TableCell>
                    <TableCell>{row.nombre}</TableCell>
                    <TableCell>{row.direccion}</TableCell>
                    <TableCell>{row.comuna}</TableCell>
                    <TableCell>{row.estado_atencion}</TableCell>
                    <TableCell>{row.equipo_medico}</TableCell>
                    <TableCell>{row.fecha}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenPatientInfoDialog(row.paciente_id, row.fecha)} sx={{ color: '#43003C' }}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoDialog} color="primary">Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPatientInfoDialog} onClose={handleClosePatientInfoDialog} maxWidth="md" fullWidth>
        <DialogTitle>Información de asignación del Paciente</DialogTitle>
        <DialogContent>
          {selectedPatient && (
            <div className="document-content">
              <h2>Datos del Paciente</h2>
              <p><strong>Nombre:</strong> {selectedPatient.nombre}</p>
              <p><strong>RUT:</strong> {selectedPatient.rut}</p>
              <p><strong>Dirección:</strong> {selectedPatient.direccion}</p>

              <h2>Estado de la Asignación</h2>
              <p><strong>Estado Asignación:</strong> {selectedPatient.estado_asignacion}</p>
              <p><strong>Estado Atención:</strong> {selectedPatient.estado_atencion}</p>
              <p><strong>Fecha:</strong> {selectedPatient.fecha}</p>

              <h2>Enfermería</h2>
              <p><strong>Control Signos Vitales:</strong> {selectedPatient.enfermeria.controlSignosVitales}</p>
              <p><strong>Evolución:</strong> {selectedPatient.enfermeria.evolucion}</p>
              <p><strong>Indicaciones:</strong> {selectedPatient.enfermeria.indicaciones}</p>
              <p><strong>Observaciones:</strong> {selectedPatient.enfermeria.observaciones}</p>
              <p><strong>Plan:</strong> {selectedPatient.enfermeria.plan}</p>

              <h2>Médico</h2>
              <p><strong>Control Signos Vitales:</strong> {selectedPatient.medico.controlSignosVitales}</p>
              <p><strong>Diagnóstico:</strong> {selectedPatient.medico.diagnostico}</p>
              <p><strong>Evolución:</strong> {selectedPatient.medico.evolucion}</p>
              <p><strong>Historia Clínica:</strong> {selectedPatient.medico.historiaClinica}</p>
              <p><strong>Indicaciones:</strong> {selectedPatient.medico.indicaciones}</p>
              <p><strong>Observaciones:</strong> {selectedPatient.medico.observaciones}</p>

              <h2>Fonoaudiólogo</h2>
              <p><strong>Control Signos Vitales:</strong> {selectedPatient.fonoaudiologo.controlSignosVitales}</p>
              <p><strong>Diagnóstico:</strong> {selectedPatient.fonoaudiologo.diagnostico}</p>
              <p><strong>Evolución:</strong> {selectedPatient.fonoaudiologo.evolucion}</p>
              <p><strong>Indicaciones:</strong> {selectedPatient.fonoaudiologo.indicaciones}</p>

              <h2>Kinesiólogo</h2>
              <p><strong>Control Signos Vitales:</strong> {selectedPatient.kinesiologo.controlSignosVitales}</p>
              <p><strong>Diagnóstico:</strong> {selectedPatient.kinesiologo.diagnostico}</p>
              <p><strong>Evolución:</strong> {selectedPatient.kinesiologo.evolucion}</p>
              <p><strong>Indicaciones:</strong> {selectedPatient.kinesiologo.indicaciones}</p>

              <h2>TENS</h2>
              <p><strong>Control Signos Vitales:</strong> {selectedPatient.tens.controlSignosVitales}</p>
              <p><strong>Evolución:</strong> {selectedPatient.tens.evolucion}</p>
              <p><strong>Observaciones:</strong> {selectedPatient.tens.observaciones}</p>

              <h2>Terapia Ocupacional</h2>
              <p><strong>Control Signos Vitales:</strong> {selectedPatient.terapiaOcupacional.controlSignosVitales}</p>
              <p><strong>Diagnóstico:</strong> {selectedPatient.terapiaOcupacional.diagnostico}</p>
              <p><strong>Evolución:</strong> {selectedPatient.terapiaOcupacional.evolucion}</p>
              <p><strong>Indicaciones:</strong> {selectedPatient.terapiaOcupacional.indicaciones}</p>
              <p><strong>Observaciones:</strong> {selectedPatient.terapiaOcupacional.observaciones}</p>

              <h2>Trabajador Social</h2>
              <p><strong>Evolución:</strong> {selectedPatient.trabajadorSocial.evolucion}</p>
              <p><strong>Observaciones:</strong> {selectedPatient.trabajadorSocial.observaciones}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={exportToPDF} color="primary">Exportar a PDF</Button>
          <Button onClick={handleClosePatientInfoDialog} color="primary">Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openFullInfoDialog} onClose={handleCloseFullInfoDialog} maxWidth="md" fullWidth>
        <DialogTitle>Información Completa del Paciente</DialogTitle>
        <DialogContent>
          {selectedFullPatient && (
            <div>
              <Typography variant="h6">Datos del Paciente</Typography>
              <p><strong>Nombre:</strong> {selectedFullPatient.nombre}</p>
              <p><strong>RUT:</strong> {selectedFullPatient.rut}</p>
              <p><strong>Dirección:</strong> {selectedFullPatient.direccion}</p>
              <p><strong>Comuna:</strong> {selectedFullPatient.comuna}</p>
              <p><strong>Estado de Asignación:</strong> {selectedFullPatient.estado_asignacion}</p>
              <p><strong>Estado de Atención:</strong> {selectedFullPatient.estado_atencion}</p>
              <p><strong>Permite Suspender:</strong> {selectedFullPatient.permite_suspender}</p>
              <p><strong>Complejidad:</strong> {selectedFullPatient.complejidad}</p>
              
              <Typography variant="h6">Datos Fisiológicos</Typography>
              <p><strong>Peso:</strong> {selectedFullPatient.datosFisiologicos.Peso}</p>
              <p><strong>Talla:</strong> {selectedFullPatient.datosFisiologicos.Talla}</p>
              <p><strong>IMC:</strong> {selectedFullPatient.datosFisiologicos.IMC}</p>
              <p><strong>Presión Arterial:</strong> {selectedFullPatient.datosFisiologicos.PresionArterial}</p>
              <p><strong>Frecuencia Cardíaca:</strong> {selectedFullPatient.datosFisiologicos.FrecuenciaCardiaca}</p>
              <p><strong>Temperatura:</strong> {selectedFullPatient.datosFisiologicos.Temperatura}</p>
              <p><strong>Frecuencia Respiratoria:</strong> {selectedFullPatient.datosFisiologicos.FrecuenciaRespiratoria}</p>
              
              <Typography variant="h6">Diagnósticos</Typography>
              <p><strong>Diagnóstico Principal:</strong> {selectedFullPatient.diagnosticos.DiagnosticoPrincipal}</p>
              <p><strong>Diagnósticos Secundarios:</strong> {selectedFullPatient.diagnosticos.DiagnosticosSecundarios}</p>
              
              <Typography variant="h6">Información Médica</Typography>
              <p><strong>Medico Tratante:</strong> {selectedFullPatient.informacionMedica.MedicoTratante}</p>
              <p><strong>Fecha de Ingreso:</strong> {selectedFullPatient.informacionMedica.FechaIngreso}</p>
              <p><strong>Alergias:</strong> {selectedFullPatient.informacionMedica.Alergias}</p>
              <p><strong>Antecedentes Patológicos Personales:</strong> {selectedFullPatient.informacionMedica.AntecedentesPatologicosPersonales}</p>
              <p><strong>Antecedentes Familiares:</strong> {selectedFullPatient.informacionMedica.AntecedentesFamiliares}</p>
              <p><strong>Antecedentes Quirúrgicos:</strong> {selectedFullPatient.informacionMedica.AntecedentesQuirurgicos}</p>
              <p><strong>Medicamentos Actuales:</strong> {selectedFullPatient.informacionMedica.MedicamentosActuales}</p>
              
              <Typography variant="h6">Resultados</Typography>
              <p><strong>Resultados de Laboratorio:</strong> {selectedFullPatient.resultados.ResultadosLaboratorio}</p>
              <p><strong>Radiografías:</strong> {selectedFullPatient.resultados.Radiografias}</p>
              <p><strong>Otros Estudios:</strong> {selectedFullPatient.resultados.OtrosEstudios}</p>
              
              <Typography variant="h6">Tratamiento</Typography>
              <p><strong>Tratamiento Actual:</strong> {selectedFullPatient.tratamiento.TratamientoActual}</p>
              <p><strong>Procedimientos Realizados:</strong> {selectedFullPatient.tratamiento.ProcedimientosRealizados}</p>
              <p><strong>Recomendaciones:</strong> {selectedFullPatient.tratamiento.Recomendaciones}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFullInfoDialog} color="primary">Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CRUDTable;
