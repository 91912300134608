import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Grid, Avatar } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";


const UserProfile = () => {

  const {user, isAuthenticated, loginWithRedirect, logout, isLoading} = useAuth0();
  let profesion = '';
  let permisos = '';
  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated) {
    const roles = user['http://localhost:3000/roles'] || [];
    const metadata = user['http://localhost:3000/metadata'] || [];
    console.log(roles);
    console.log(metadata);
    profesion = metadata;
    permisos = roles[0];
  }

  const defaultPicture = '/static/images/default-avatar.png';


  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }}>
  {(isAuthenticated)&&(
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardMedia>
            <Avatar 
              alt="User Name" 
              src={user?.picture || defaultPicture}
              sx={{ width: 120, height: 120, margin: 'auto', marginTop: 2 }}
            />
          </CardMedia>
          <CardContent>
            <Typography variant="h5" component="div" textAlign="center">
              {user?.name || 'Default User Name'}
            </Typography>
            <Typography variant="body2" color="text.secondary" textAlign="center">
              {profesion} en HSJD
            </Typography>
            <Typography variant="body2" color="text.secondary" textAlign="center">
            {user?.name || 'Default User Email'}
            </Typography>
            <Typography variant="body2" color="text.secondary" textAlign="center" marginTop={0}>
              Usuario posee permisos de {permisos}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )}
    </Grid>
  );
}

export default UserProfile;