import React, { useState, useEffect } from 'react';
import { useLocation, Link  } from 'react-router-dom';
import axios from 'axios';
import {
  Modal,
  Container,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2, // Añadir bordes redondeados
};


const formatDate = (date) => {
  const d = new Date(date);
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const day = ('0' + d.getDate()).slice(-2);
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

const apiUrlPatients = 'https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-pacientes?estado_asignacion=NO_ASIGNADO';
const apiUrlTeams = 'https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-equipos';

const PatientAssignment = () => {
  const [assignments, setAssignments] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
  const [apiPatients, setApiPatients] = useState([]);
  const [availablePatients, setAvailablePatients] = useState([]);
  const [teams, setTeams] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [assignmentsModal, setAssignmentsModal] = useState([]);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await axios.get(apiUrlPatients);
        setApiPatients(response.data);
        setAvailablePatients(response.data);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };

    const fetchTeams = async () => {
      try {
        const response = await axios.get(apiUrlTeams);
        setTeams(response.data);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    fetchPatients();
    fetchTeams();
  }, []);

  const handleAssign = () => {
    if (selectedPatient && selectedTeam && selectedDate) {
      const newAssignment = { patient: selectedPatient, team: selectedTeam, date: selectedDate };
      setAssignments([...assignments, newAssignment]);
      setSelectedPatient('');
      setSelectedTeam('');
      setSelectedDate(formatDate(new Date()));

      setAvailablePatients(prevPatients => prevPatients.filter(patient => patient.paciente_id !== selectedPatient.paciente_id));
    }
  };

  const handleRemoveAssignment = (index) => {
    setAssignments(assignments.filter((_, i) => i !== index));
    const removedPatient = assignments[index].patient;
    setAvailablePatients([...availablePatients, removedPatient]);
  };

  const handleConfirmAssignments = async () => {
    try {

      
      const assignmentsPayload = assignments.map(assignment => ({
        
        paciente_id: assignment.patient.paciente_id,
        equipo_medico: assignment.team.equipo_id,
        fecha: assignment.date
      }));
      

      // Aquí deberías realizar la llamada POST a tu endpoint Lambda
      const response = await axios.post('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/asignar-pacientes', assignmentsPayload);
      
      setModalMessage(response.data.message);
      setAssignmentsModal(response.data.asignacion.asignacion);
      setModalIsOpen(true);

      console.log('Asignaciones:', assignmentsPayload);
    } catch (error) {
      console.error('Error sending assignments to Lambda:', error);
      setModalMessage('Error asignando pacientes.');
      setAssignmentsModal([]);
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    window.location.reload();
  };

  return (
    <Container>
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            {modalMessage}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}></Typography>
          {assignmentsModal.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Paciente ID</TableCell>
                    <TableCell>Equipo Médico</TableCell>
                    <TableCell>Fecha</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assignmentsModal.map((assignmentsModal, index) => (
                    <TableRow key={index}>
                      <TableCell>{assignmentsModal.paciente_id}</TableCell>
                      <TableCell>{assignmentsModal.equipo_medico}</TableCell>
                      <TableCell>{assignmentsModal.fecha}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Button onClick={closeModal} sx={{ mt: 2 }}>Cerrar</Button>
        </Box>
      </Modal>
      <Box justifyContent="center" align="center" sx={{ backgroundColor: '#003C43', padding: 1, borderRadius: 1 }}>
        <Typography variant="h6" align="center" color="white">
          Asignación de pacientes a equipos médicos
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 2 }}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ boxShadow: 6, padding: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ marginTop: 0.5 }} align="center" color="secondary">
              Pacientes
            </Typography>
            <List>
              {availablePatients.length > 0 ? availablePatients.map((patient) => (
                <ListItem
                  button
                  key={patient.paciente_id}
                  selected={selectedPatient === patient}
                  onClick={() => setSelectedPatient(patient)}
                  sx={{ boxShadow: selectedPatient === patient ? 3 : 0, marginBottom: 1, borderRadius: 1 }}
                >
                  <ListItemText primary={patient.nombre} />
                </ListItem>
              )) : <Typography variant="body1" align="center">No hay pacientes disponibles</Typography>}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ boxShadow: 6, padding: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ marginTop: 0.5 }} align="center" color="secondary">
              Equipos Médicos
            </Typography>
            <List>
              {teams.length > 0 ? teams.map((team) => (
                <ListItem
                  button
                  key={team.equipo_id}
                  selected={selectedTeam === team}
                  onClick={() => setSelectedTeam(team)}
                  sx={{ boxShadow: selectedTeam === team ? 3 : 0, marginBottom: 1, borderRadius: 1 }}
                >
                  <ListItemText primary={`${team.equipo_nombre}`} />
                </ListItem>
              )) : <Typography variant="body1" align="center">No hay equipos disponibles</Typography>}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ boxShadow: 6, padding: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ marginTop: 0.5 }} align="center" color="secondary">
              Seleccionar Fecha
            </Typography>
            <TextField
              type="date"
              fullWidth
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            <Button variant="contained" color="primary" fullWidth onClick={handleAssign} sx={{ marginTop: 2, boxShadow: 3 }}>
              Asignar
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ marginTop: 3, boxShadow: 6 }}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: '#002A2E' }}>
            <TableRow>
              <TableCell>
                <Typography color="white" fontWeight="bold">Paciente</Typography>
              </TableCell>
              <TableCell>
                <Typography color="white" fontWeight="bold">Equipo Médico</Typography>
              </TableCell>
              <TableCell>
                <Typography color="white" fontWeight="bold">Fecha</Typography>
              </TableCell>
              <TableCell>
                <Typography color="white" fontWeight="bold">Eliminar</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignments.map((assignment, index) => (
              <TableRow key={index} sx={{ boxShadow: 3 }}>
                <TableCell>{assignment.patient.nombre}</TableCell>
                <TableCell>{`Equipo ${assignment.team.equipo_id}`}</TableCell>
                <TableCell>{assignment.date}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleRemoveAssignment(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleConfirmAssignments}
        sx={{ marginTop: 3, boxShadow: 3 }}
      >
        Confirmar asignación
      </Button>

      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 3 }}>
        <Link to="/CrudPatientAssignment" style={{ textDecoration: 'none' }}>
          <Button variant="contained" sx={{ backgroundColor: '#77B0AA', color: 'white', '&:hover': { backgroundColor: '#77B0AA' }, minWidth: '80px' }}>
            Volver
          </Button>
        </Link>
      </Box>
    </Container>
  );
};

export default PatientAssignment;
