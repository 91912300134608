// src/components/PatientForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, TextField, Button, Grid, Box, Typography, MenuItem, Select, InputLabel, FormControl, Paper, FormHelperText } from '@mui/material';


const PatientForm = () => {
  const [formData, setFormData] = useState({
    identificacion: {
      RUT: '',
      NumeroHistoriaClinica: ''
    },
    informacionPersonal: {
      Nombre: '',
      FechaNacimiento: '',
      Edad: '',
      Genero: '',
      EstadoCivil: '',
      Ocupacion: '',
      Direccion: '',
      Telefono: '',
      CorreoElectronico: ''
    },
    informacionMedica: {
      FechaIngreso: '',
      MedicoTratante: '',
      AntecedentesFamiliares: '',
      AntecedentesPatologicosPersonales: '',
      AntecedentesQuirurgicos: '',
      Alergias: '',
      MedicamentosActuales: ''
    },
    datosFisiologicos: {
      Peso: '',
      Talla: '',
      IMC: '',
      PresionArterial: '',
      FrecuenciaCardiaca: '',
      Temperatura: '',
      FrecuenciaRespiratoria: ''
    },
    diagnosticos: {
      DiagnosticoPrincipal: '',
      DiagnosticosSecundarios: ''
    },
    tratamiento: {
      TratamientoActual: '',
      ProcedimientosRealizados: '',
      Recomendaciones: ''
    },
    resultados: {
      ResultadosLaboratorio: '',
      Radiografias: '',
      OtrosEstudios: ''
    },
    observaciones: {
      ObservacionesAdicionales: ''
    }
  });

  const { getAccessTokenSilently } = useAuth0();

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split('.');
    setFormData(prevState => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: value
      }
    }));
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.identificacion = {};
    tempErrors.informacionPersonal = {};

    if (!formData.identificacion.RUT) tempErrors.identificacion.RUT = "RUT es requerido";
    if (!formData.informacionPersonal.Nombre) tempErrors.informacionPersonal.Nombre = "Nombre es requerido";
    if (!formData.informacionPersonal.FechaNacimiento) tempErrors.informacionPersonal.FechaNacimiento = "Fecha de Nacimiento es requerida";
    if (!formData.informacionPersonal.Edad) tempErrors.informacionPersonal.Edad = "Edad es requerida";
    if (!formData.informacionPersonal.Genero) tempErrors.informacionPersonal.Genero = "Género es requerido";

    setErrors(tempErrors);

    return Object.keys(tempErrors).every(key => Object.keys(tempErrors[key]).length === 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const apiUrl = 'https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/ingreso-paciente';

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response.data);
      alert('Paciente ingresado correctamente');
    } catch (error) {
      console.error('Error al ingresar paciente:', error);
      alert('Error al ingresar paciente');
    }
  };

  return (
    <Container >
      <Box >

        <Box justifyContent="center" align="center" sx={{ backgroundColor: '#003C43', padding: 1, borderRadius: 1 }}>
          <Typography variant="h6" align="center" color="white">
            Nuevo Ingreso
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Identificación
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="RUT"
                  name="identificacion.RUT"
                  value={formData.identificacion.RUT}
                  onChange={handleChange}
                  error={!!errors.identificacion?.RUT}
                  helperText={errors.identificacion?.RUT}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Número de Historia Clínica"
                  name="identificacion.NumeroHistoriaClinica"
                  value={formData.identificacion.NumeroHistoriaClinica}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required error={!!errors.informacionPersonal?.Genero}>
                  <InputLabel>Complejidad</InputLabel>
                  <Select
                    name="informacionPersonal.Complejidad"
                    value={formData.informacionPersonal.Complejidad}
                    onChange={handleChange}
                  >
                    <MenuItem value="alta">Alta</MenuItem>
                    <MenuItem value="media">Media</MenuItem>
                    <MenuItem value="baja">Baja</MenuItem>
                  </Select>
                  {errors.informacionPersonal?.Complejidad && <FormHelperText>{errors.informacionPersonal.Complejidad}</FormHelperText>}
                </FormControl>
              </Grid>

            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Información Personal
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="informacionPersonal.Nombre"
                  value={formData.informacionPersonal.Nombre}
                  onChange={handleChange}
                  error={!!errors.informacionPersonal?.Nombre}
                  helperText={errors.informacionPersonal?.Nombre}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Fecha de Nacimiento"
                  name="informacionPersonal.FechaNacimiento"
                  value={formData.informacionPersonal.FechaNacimiento}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.informacionPersonal?.FechaNacimiento}
                  helperText={errors.informacionPersonal?.FechaNacimiento}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Edad"
                  name="informacionPersonal.Edad"
                  value={formData.informacionPersonal.Edad}
                  onChange={handleChange}
                  error={!!errors.informacionPersonal?.Edad}
                  helperText={errors.informacionPersonal?.Edad}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required error={!!errors.informacionPersonal?.Genero}>
                  <InputLabel>Género</InputLabel>
                  <Select
                    name="informacionPersonal.Genero"
                    value={formData.informacionPersonal.Genero}
                    onChange={handleChange}
                  >
                    <MenuItem value="Masculino">Masculino</MenuItem>
                    <MenuItem value="Femenino">Femenino</MenuItem>
                    <MenuItem value="Otro">Otro</MenuItem>
                  </Select>
                  {errors.informacionPersonal?.Genero && <FormHelperText>{errors.informacionPersonal.Genero}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Estado Civil"
                  name="informacionPersonal.EstadoCivil"
                  value={formData.informacionPersonal.EstadoCivil}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Ocupación"
                  name="informacionPersonal.Ocupacion"
                  value={formData.informacionPersonal.Ocupacion}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Dirección"
                  name="informacionPersonal.Direccion"
                  value={formData.informacionPersonal.Direccion}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Teléfono"
                  name="informacionPersonal.Telefono"
                  value={formData.informacionPersonal.Telefono}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="email"
                  label="Correo Electrónico"
                  name="informacionPersonal.CorreoElectronico"
                  value={formData.informacionPersonal.CorreoElectronico}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Información Médica
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Fecha de Ingreso"
                  name="informacionMedica.FechaIngreso"
                  value={formData.informacionMedica.FechaIngreso}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Médico Tratante"
                  name="informacionMedica.MedicoTratante"
                  value={formData.informacionMedica.MedicoTratante}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Antecedentes Familiares"
                  name="informacionMedica.AntecedentesFamiliares"
                  value={formData.informacionMedica.AntecedentesFamiliares}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Antecedentes Patológicos Personales"
                  name="informacionMedica.AntecedentesPatologicosPersonales"
                  value={formData.informacionMedica.AntecedentesPatologicosPersonales}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Antecedentes Quirúrgicos"
                  name="informacionMedica.AntecedentesQuirurgicos"
                  value={formData.informacionMedica.AntecedentesQuirurgicos}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Alergias"
                  name="informacionMedica.Alergias"
                  value={formData.informacionMedica.Alergias}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Medicamentos Actuales"
                  name="informacionMedica.MedicamentosActuales"
                  value={formData.informacionMedica.MedicamentosActuales}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Datos Fisiológicos
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Peso"
                  name="datosFisiologicos.Peso"
                  value={formData.datosFisiologicos.Peso}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Talla"
                  name="datosFisiologicos.Talla"
                  value={formData.datosFisiologicos.Talla}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  step="0.01"
                  label="IMC"
                  name="datosFisiologicos.IMC"
                  value={formData.datosFisiologicos.IMC}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Presión Arterial"
                  name="datosFisiologicos.PresionArterial"
                  value={formData.datosFisiologicos.PresionArterial}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Frecuencia Cardíaca"
                  name="datosFisiologicos.FrecuenciaCardiaca"
                  value={formData.datosFisiologicos.FrecuenciaCardiaca}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  step="0.1"
                  label="Temperatura"
                  name="datosFisiologicos.Temperatura"
                  value={formData.datosFisiologicos.Temperatura}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Frecuencia Respiratoria"
                  name="datosFisiologicos.FrecuenciaRespiratoria"
                  value={formData.datosFisiologicos.FrecuenciaRespiratoria}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Diagnósticos
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Diagnóstico Principal"
                  name="diagnosticos.DiagnosticoPrincipal"
                  value={formData.diagnosticos.DiagnosticoPrincipal}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Diagnósticos Secundarios"
                  name="diagnosticos.DiagnosticosSecundarios"
                  value={formData.diagnosticos.DiagnosticosSecundarios}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Tratamiento
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Tratamiento Actual"
                  name="tratamiento.TratamientoActual"
                  value={formData.tratamiento.TratamientoActual}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Procedimientos Realizados"
                  name="tratamiento.ProcedimientosRealizados"
                  value={formData.tratamiento.ProcedimientosRealizados}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Recomendaciones"
                  name="tratamiento.Recomendaciones"
                  value={formData.tratamiento.Recomendaciones}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Resultados
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Resultados de Laboratorio"
                  name="resultados.ResultadosLaboratorio"
                  value={formData.resultados.ResultadosLaboratorio}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Radiografías"
                  name="resultados.Radiografias"
                  value={formData.resultados.Radiografias}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Otros Estudios"
                  name="resultados.OtrosEstudios"
                  value={formData.resultados.OtrosEstudios}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Observaciones
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Observaciones Adicionales"
                  name="observaciones.ObservacionesAdicionales"
                  value={formData.observaciones.ObservacionesAdicionales}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Box sx={{ mt: 3 }}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Ingresar Paciente
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default PatientForm;
