import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Grid, Paper, Typography, AppBar, Toolbar, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Person2Icon from '@mui/icons-material/Person2';
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LineChart,
  Line,
  AreaChart,
  Area,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar
} from 'recharts';

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  boxShadow: 3,
}));

const COLORS = ['#14847c','#0f736c','#0a635c','#05524b','#00413b', '#b3c6c4','#233836','#849190','#12a599','#769391'];

const Home = () => {
  const [pacientesCount, setPacientesCount] = useState(0);
  const [equiposCount, setEquiposCount] = useState(0);
  const [profesionalesCount, setProfesionalesCount] = useState(0);
  const [dataDoughnut, setDataDoughnut] = useState([]);
  const [dataBar, setDataBar] = useState([]);
  const [dataEquiposBar, setDataEquiposBar] = useState([]);
  const [dataProfesionalesPie, setDataProfesionalesPie] = useState([]);
  const [dataEstadoFichaBar, setDataEstadoFichaBar] = useState([]);
  const [dataAsignacionesFechaLine, setDataAsignacionesFechaLine] = useState([]); // Cambio aquí
  const [dataMotivoRetiroPie, setDataMotivoRetiroPie] = useState([]);
  const [totalRetirados, setTotalRetirados] = useState(0);
  const [dataEstadoAtencionPie, setDataEstadoAtencionPie] = useState([]);
  const [dataGeneroPie, setDataGeneroPie] = useState([]);
  const [dataEstadoAsignacionPie, setDataEstadoAsignacionPie] = useState([]);
  const [dataEstadoFichaPie, setDataEstadoFichaPie] = useState([]);
  const [dataAsignacionesPorEquipo, setDataAsignacionesPorEquipo] = useState([]);
  const [dataAsignacionesArea, setDataAsignacionesArea] = useState([]);
  const [dataAsignacionesRadar, setDataAsignacionesRadar] = useState([]);

  useEffect(() => {
    const fetchPacientes = async () => {
      try {
        const response = await axios.get('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-pacientes');
        const pacientes = response.data;
        setPacientesCount(pacientes.length);

        const activos = pacientes.filter(p => p.estado_asignacion === 'ASIGNADO').length;
        const inactivos = pacientes.filter(p => p.estado_asignacion === 'NO_ASIGNADO' || p.estado_asignacion === '').length;

        setDataDoughnut([
          { name: 'Pacientes agendados', value: activos, color: '#52b152' },
          { name: 'Pacientes en espera', value: inactivos, color: '#008a00' },
        ]);

        const equiposResponse = await axios.get('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-equipos');
        const equipos = equiposResponse.data;
        setEquiposCount(equipos.length);
        
        const profesionalesResponse = await axios.get('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-profesionales');
        const profesionales = profesionalesResponse.data;
        setProfesionalesCount(profesionales.length);

        // Datos para gráficos
        const equiposBarData = equipos.map(e => ({
          name: e.equipo_nombre,
          profesionales: e.profesionales.length,
        }));

        setDataEquiposBar(equiposBarData);

        const tiposProfesionales = profesionales.reduce((acc, curr) => {
          const tipo = curr.especialidad;
          if (!acc[tipo]) acc[tipo] = 0;
          acc[tipo]++;
          return acc;
        }, {});

        const profesionalesPieData = Object.keys(tiposProfesionales).map(tipo => ({
          name: tipo || 'Sin Especificar',
          value: tiposProfesionales[tipo],
        }));

        setDataProfesionalesPie(profesionalesPieData);

        // Procesando datos para el gráfico de motivo de retiro
        const motivosRetiro = pacientes.reduce((acc, paciente) => {
          if (paciente.estado_atencion === 'RETIRO') {
            const motivo = paciente.motivo_retiro || 'Sin Motivo Especificado';
            if (!acc[motivo]) {
              acc[motivo] = 0;
            }
            acc[motivo] += 1;
          }
          return acc;
        }, {});

        const totalRetiradosCount = Object.values(motivosRetiro).reduce((sum, value) => sum + value, 0);
        setTotalRetirados(totalRetiradosCount);

        const formattedMotivoRetiroData = Object.keys(motivosRetiro).map(motivo => ({
          name: motivo,
          value: motivosRetiro[motivo],
        }));

        setDataMotivoRetiroPie(formattedMotivoRetiroData);

        // Procesando datos para el gráfico de estado de atención
        const estadoAtencionData = pacientes.reduce((acc, paciente) => {
          const estado = paciente.estado_atencion || 'Desconocido';
          if (!acc[estado]) {
            acc[estado] = 0;
          }
          acc[estado] += 1;
          return acc;
        }, {});

        const formattedEstadoAtencionData = Object.keys(estadoAtencionData).map(estado => ({
          name: estado,
          value: estadoAtencionData[estado],
        }));

        setDataEstadoAtencionPie(formattedEstadoAtencionData);

        // Procesando datos para el gráfico de género
        const generoData = pacientes.reduce((acc, paciente) => {
          const genero = paciente.informacionPersonal?.Genero || 'Desconocido';
          if (!acc[genero]) {
            acc[genero] = 0;
          }
          acc[genero] += 1;
          return acc;
        }, {});

        const formattedGeneroData = Object.keys(generoData).map(genero => ({
          name: genero,
          value: generoData[genero],
        }));

        setDataGeneroPie(formattedGeneroData);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchAsignaciones = async () => {
      try {
        const response = await axios.get('https://dlgc7j6fg4.execute-api.us-east-1.amazonaws.com/dev/obtener-asignacion-pacientes');
        const asignaciones = response.data;

        const estadoAsignacionData = asignaciones.reduce((acc, asignacion) => {
          const estado = asignacion.estado_asignacion || 'Desconocido';
          if (!acc[estado]) {
            acc[estado] = 0;
          }
          acc[estado] += 1;
          return acc;
        }, {});

        const formattedEstadoAsignacionData = Object.keys(estadoAsignacionData).map(estado => ({
          name: estado,
          value: estadoAsignacionData[estado],
        }));

        setDataEstadoAsignacionPie(formattedEstadoAsignacionData);

        const estadoFichaData = asignaciones.reduce((acc, asignacion) => {
          Object.keys(asignacion.estado_ficha).forEach(ficha => {
            const estado = asignacion.estado_ficha[ficha] || 'Desconocido';
            if (!acc[estado]) {
              acc[estado] = 0;
            }
            acc[estado] += 1;
          });
          return acc;
        }, {});

        const formattedEstadoFichaData = Object.keys(estadoFichaData).map(estado => ({
          name: estado,
          value: estadoFichaData[estado],
        }));

        setDataEstadoFichaPie(formattedEstadoFichaData);

        const asignacionesPorEquipoData = asignaciones.reduce((acc, asignacion) => {
          const equipo = asignacion.equipo_medico || 'Desconocido';
          if (!acc[equipo]) {
            acc[equipo] = 0;
          }
          acc[equipo] += 1;
          return acc;
        }, {});

        const formattedAsignacionesPorEquipoData = Object.keys(asignacionesPorEquipoData).map(equipo => ({
          name: equipo,
          value: asignacionesPorEquipoData[equipo],
        }));

        setDataAsignacionesPorEquipo(formattedAsignacionesPorEquipoData);

        // Datos para gráficos adicionales

        const asignacionesAreaData = asignaciones.map(asignacion => ({
          name: asignacion.nombre,
          asignaciones: 1,
        }));

        setDataAsignacionesArea(asignacionesAreaData);

        const asignacionesRadarData = Object.keys(asignacionesPorEquipoData).map(equipo => ({
          name: equipo,
          Asignaciones: asignacionesPorEquipoData[equipo],
          fullMark: 150,
        }));

        setDataAsignacionesRadar(asignacionesRadarData);

        // Procesando datos para el gráfico de asignaciones por fecha
        const asignacionesFechaData = asignaciones.reduce((acc, asignacion) => {
          const fecha = asignacion.fecha;
          if (!acc[fecha]) {
            acc[fecha] = 0;
          }
          acc[fecha] += 1;
          return acc;
        }, {});

        const formattedAsignacionesFechaData = Object.keys(asignacionesFechaData).map(fecha => ({
          fecha,
          cantidad: asignacionesFechaData[fecha],
        }));

        setDataAsignacionesFechaLine(formattedAsignacionesFechaData); // Cambio aquí

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchPacientes();
    fetchAsignaciones();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <AppBar position="static" sx={{ bgcolor: 'white', marginBottom: 2, boxShadow: 3 }}>
        <Toolbar sx={{ justifyContent: 'center' }}>
          <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
            Estadísticas de la unidad domiciliaria del Hospital San Juan de Dios
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard elevation={3}>
            <MedicalInformationIcon sx={{ fontSize: 40, color: '#002A2E' }} />
            <Typography variant="h6">Profesionales</Typography>
            <Typography variant="h4">{profesionalesCount}</Typography>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard elevation={3}>
            <Person2Icon sx={{ fontSize: 40, color: '#002A2E' }} />
            <Typography variant="h6">Pacientes</Typography>
            <Typography variant="h4">{pacientesCount}</Typography>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard elevation={3}>
            <LocalHospitalIcon sx={{ fontSize: 40, color: '#002A2E' }} />
            <Typography variant="h6">Equipos</Typography>
            <Typography variant="h4">{equiposCount}</Typography>
          </StatCard>
        </Grid>

        {/* Especialidad de los profesionales */}
        <Grid item xs={12} md={6} lg={6}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Especialidad de los profesionales
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie data={dataProfesionalesPie} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value" label>
                  {dataProfesionalesPie.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Estado de la entrada de pacientes */}
        <Grid item xs={12} md={6} lg={6}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Estado de la entrada de pacientes
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie data={dataDoughnut} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value" label>
                  {dataDoughnut.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Historial Pacientes */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Historial Pacientes
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={dataBar}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="nuevosPacientes" fill="#135D66" label>
                  {dataBar.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Estado de las fichas de los pacientes */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Estado de las fichas de los pacientes
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={dataEstadoFichaBar}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="PENDIENTE" fill="#ffbb28" />
                <Bar dataKey="BORRADOR" fill="#ff8042" />
                <Bar dataKey="COMPLETA" fill="#0088FE" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Número de Asignaciones por Fecha */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Número de Asignaciones por Fecha
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={dataAsignacionesFechaLine}> {/* Cambio aquí */}
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="fecha" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="cantidad" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Pacientes Retirados */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Pacientes Retirados (Total: {totalRetirados})
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie data={dataMotivoRetiroPie} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value" label>
                  {dataMotivoRetiroPie.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Estado de Atención de Pacientes */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Estado de Atención de Pacientes
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie data={dataEstadoAtencionPie} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value" label>
                  {dataEstadoAtencionPie.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Pacientes por Género */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Pacientes por Género
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie data={dataGeneroPie} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value" label>
                  {dataGeneroPie.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Estado de Asignación de Pacientes */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Estado de Asignación de Pacientes
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie data={dataEstadoAsignacionPie} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value" label>
                  {dataEstadoAsignacionPie.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Estado de las Fichas por Paciente */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Estado de las Fichas por Paciente
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie data={dataEstadoFichaPie} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value" label>
                  {dataEstadoFichaPie.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Asignaciones por Equipo */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Asignaciones por Equipo
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={dataAsignacionesPorEquipo}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#135D66" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Distribución de Asignaciones (Área) */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Distribución de Asignaciones
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={dataAsignacionesArea}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey="asignaciones" stroke="#8884d8" fill="#8884d8" />
              </AreaChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Radar Chart for Asignaciones */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ boxShadow: 4, p: 2, display: 'flex', flexDirection: 'column', height: 400, alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ color: '#002A2E', fontWeight: 'bold', fontSize: '20px' }}>
              Radar de Asignaciones por Equipo
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart data={dataAsignacionesRadar}>
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <PolarRadiusAxis angle={30} domain={[0, 150]} />
                <Radar name="Asignaciones" dataKey="Asignaciones" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                <Tooltip />
                <Legend />
              </RadarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

      </Grid>
    </Box>
  );
};

export default Home;
