import React from "react";
import loading from "../assets/loading.svg";
import { Grid } from '@mui/material';

const Loading = () => (
<Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }}>
    <div className="spinner">
      <img src={loading} alt="Loading" />
    </div>
</Grid>
);

export default Loading;


